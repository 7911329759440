const useApplicantItemData = (firebase, tenantId) => {
    const getApplicationQuestionnaire = async(questionnaireId) => 
        firebase.questionnaire(tenantId, questionnaireId)
        .get()
        .then(q => {
            if (q.exists) {
                let questionnaire = q.data();
                let questionnaireJSON = JSON.parse(questionnaire.definition);

                return { questionnaire, questionnaireJSON };
            } else {
                return { undefined, undefined }
            }
        });

    const getApplicationData = async(appId) =>
        firebase.application(tenantId, appId)
            .get()
            .then(a => {
                if (a.exists) {
                    let application = a.data()
                    
                    let applicationJSON = JSON.parse(application.rawData);
                    
                    // legacy cruft for TTC
                    if (application.questionnaireId === "d36U3FaLmdrM5f79Tg03") {
                        applicationJSON.phone = [applicationJSON["ph1"],applicationJSON["ph2"],applicationJSON["ph3"]].join("-");
                        applicationJSON.empPhone1 = [applicationJSON["QID_102_1_EmpPh1"],applicationJSON["QID_102_1_EmpPh2"],applicationJSON["QID_102_1_EmpPh3"]].join('-');
                        applicationJSON.empPhone2 = [applicationJSON["QID_102_2_EmpPh1"],applicationJSON["QID_102_2_EmpPh2"],applicationJSON["QID_102_2_EmpPh3"]].join('-');
                    }
                    return { application, applicationJSON };
                } else {
                    return { undefined, undefined };
                }
            });

    return {
        getApplicationData,
        getApplicationQuestionnaire
    }
}

export default useApplicantItemData;