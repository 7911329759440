import React, { useState } from 'react';
import MSHContainer from '../Common/mysimplehireContainer';

export default (props) => {
    return (
        <MSHContainer>
            <div>
                <div className="row m-2">
                    <div className="d-none d-md-block col-md-7 text-left">
                        <h2 className="mt-5">{props.title}</h2>
                        <div>{props.description}</div>
                    </div>
                    <div className="col-12 d-md-none">
                        <div className="d-md-none mb-4">
                            <img src={props.image} alt={props.alt} style={{maxHeight: 150}}/>
                        </div>
                        <h2>{props.title}</h2>
                        <div>{props.description}</div>
                    </div>
                    <div className="d-none d-md-block col-md-5">
                    <img src={props.image} alt={props.alt} style={{maxHeight: 150}}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="p-2">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </MSHContainer>
    )
}