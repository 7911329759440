import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const QuestionnairesBase = (props) => {

    const [isLoading, setLoading] = useState(false);
    const [questionnaires, setquestionnaires] = useState([]);
    const currentUser = props.authUser;

    useEffect(() => {
        setLoading(true);

        let query = props.firebase
                        .tenantLevelQuestionnaires(props.authUser.tenantId);

        if (!props.authUser.tenant_admin)
            query = query.where("siteId", "in", props.authUser.sites);

        const unsubscribe =
            query
            .onSnapshot(snapshot => {
                let questionnaires = [];

                snapshot.forEach(doc => {
                    let data = doc.data();
                    questionnaires.push( { ...data, id: doc.id, lastUpdatedLocal: data.lastUpdated.toDate().toString() });
                })

                setquestionnaires(questionnaires);
                setLoading(false);
            })

        return () => {
            unsubscribe();
        }
    }, [props]);

    const localDateTime = (display) => {
        let newDate = new Date(display);
        return newDate.toLocaleDateString() + " " + newDate.toLocaleTimeString();
    }
    return (
        <div id='questionnaireListContent'>
            <h2>Questionnaires</h2>

                {isLoading && <div>Loading...</div>}

                {questionnaires
                    ? <div>
                        <ul className="list-group">
                        { questionnaires.map(questionnaire => (
                            <li key={questionnaire.id} className="list-group-item">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-6">
                                                <p>
                                                    <strong>{questionnaire.title}</strong>
                                                    <br/>{questionnaire.description} 
                                                </p>
                                            </div>
                                            <div className="col-3">
                                                { localDateTime(questionnaire.lastUpdatedLocal) }
                                            </div>
                                            <div className="col-3">
                                                <Link to ={{
                                                    pathname: `${ROUTES.QUESTIONNAIRES}/${questionnaire.id}`,
                                                    state: {
                                                        questionnaire,
                                                        currentUser
                                                    },
                                                }}>Details</Link>
                                            </div>
                                        </div>
                                    </div>
                            </li>
                        ))}
                        </ul>
                    </div>
                    : <div>No questionnaires have been received for your location.</div>
                }
        </div>
    );
}

const QuestionnairesList = withFirebase(QuestionnairesBase);

export default QuestionnairesList;