import React, { useState, useEffect } from 'react';
import moment from 'moment';
import OffCanvasSidebar from '../Common/OffCanvasSidebar';
import { currentAuth } from '../Session';
import { withFirebase } from '../Firebase';

const ApplicantNotesSidebar = ({applicantName, applicationId, show, onClose, firebase}) => {
    const [newNoteText, setNewNoteText] = useState('');
    const [showEntry, setShowEntry] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notes, setNotes] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    const { tenantId } = currentAuth();

    useEffect(() => {
        let query = firebase.applicationNotes(tenantId, applicationId)
                        .orderBy("timestamp", "desc");

        const timestampToDateString = (fbTS) => moment(new Date((fbTS).seconds * 1000)).format('l LT');


        const unsubscribe =
                        query
                        .onSnapshot(snapshot => {
                            let noteItems = [];
                            if (!snapshot.empty) {
                                snapshot.forEach(doc => {
                                    let data = doc.data()
                                    noteItems.push( {
                                        id: doc.id,
                                        note: data.note,
                                        reportingId: data.reportingId,
                                        displayTS: timestampToDateString(data.timestamp)
                                    });
                                })
                            }
                            setNotes([...noteItems]);
                            setLoading(false);
                        });
            
        return () => {
            unsubscribe();
        }

    }, []);

    const updateNoteText = (e) => {
        setNewNoteText(e.target.value);
    }

    const cancelNote = () => {
        setShowEntry(false);
        setNewNoteText('');
    }

    const saveNote = () => {
        setIsSaving(true);
        firebase.applicationAddNote(tenantId, applicationId, newNoteText)
            .then(x => {
                setIsSaving(false);
                setShowEntry(false);
                setNewNoteText('');
            })
            .catch(err => {
                setIsSaving(false);
            })
    }

    return (
        <OffCanvasSidebar
            show={show}
            title={`${applicantName} Notes`}
            onClose={onClose}>
            { loading &&
                <div><span className="fa fa-spinner fa-spin mr-2"></span>Loading...</div>
            }
            { !showEntry && 
                <div className='m-2'>
                    <button
                        onClick={() => setShowEntry(true)}
                        className='btn btn-primary w-100'>
                            Add Note
                    </button>
                </div>
            }
            { showEntry && 
                <div className='card'>
                    <div className='card-header'>New Note</div>
                    <div className='card-body'>
                        <textarea
                            className='form-control'
                            value={newNoteText}
                            onChange={updateNoteText}></textarea>
                    </div>
                    <div className='card-footer'>
                        <button
                            onClick={cancelNote}
                            className='btn btn-link'
                            disabled={isSaving}
                            >
                                Cancel
                        </button>

                        <button
                            onClick={saveNote}
                            disabled={isSaving}
                            className='btn btn-sm btn-success btn-pill pull-right mt-1'>
                                { isSaving && 
                                    <>Saving... <span className="fa fa-spinner fa-spin mr-2"></span></>
                                }
                                { !isSaving && 
                                    <>Save Note</>
                                }
                        </button>
                    </div>
                </div>
            }
            <div className='pb-5'>
            { notes && notes.length > 0 && 
            <>
                { notes.map((x, idx) => (
                    <div className='border m-1 p-1 appNoteText' key={idx}>
                        <label>{x.displayTS} ({x.reportingId})</label>
                        <p>{x.note}</p>
                    </div>
                ))}
            </>
            }
            </div>

        </OffCanvasSidebar>
    )
}



export default withFirebase(ApplicantNotesSidebar);