import app from 'firebase/app';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import * as ROUTES from '../../constants/routes';
import * as DATAACTIONS from '../../constants/dataActions';

const prodConfig = {
    apiKey: process.env.REACT_APP_PROD_API_KEY,
    authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
    projectId: process.env.REACT_APP_PROD_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_PROD_APP_ID,
    measurementId: process.env.REACT_APP_PROD_MEASUREMENT_ID,
};
   
const devConfig = {
    apiKey: process.env.REACT_APP_DEV_API_KEY,
    authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
    projectId: process.env.REACT_APP_DEV_PROJECT_ID,
    storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_DEV_APP_ID,
    measurementId: process.env.REACT_APP_DEV_MEASUREMENT_ID,
};
   
const config = process.env.REACT_APP_ENV_ID === 'PRD' ? prodConfig : devConfig;

class Firebase {
    constructor() {
        app.initializeApp(config);

        this.auth = app.auth();

        this.rtdb = app.database();

        this.fs = app.firestore();
        
        this.fn = app.functions();

        // *** Helpers ***
        this.fsNow = () => app.firestore.Timestamp.now();

        this.fsTimestampFromString = (d) => {
            var ts = Math.round(Date.parse(d) / 1000);
            return new app.firestore.Timestamp(ts, 0);
        }

        this.fsTimestampFromTS = (ts) => {
            return new app.firestore.Timestamp(ts/1000, 0);
        } 
    }
   
    // *** Auth API ***
    doCustomerOnboard = async(fields) => {
        try {
            await this.fn.httpsCallable('apiAuth-customerOnboard')(fields);
            return this.auth.signInWithEmailAndPassword(fields.email, fields.passwordOne);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    doCreateUserWithEmailAndPassword = (email, username, companyName, password) =>
        this.fn.httpsCallable('apiAuth-standardSignUp')({email, username, companyName, password})
        .then(() => {
            return this.auth.signInWithEmailAndPassword(email, password);
        });
   
    doSignInWithEmailAndPassword = (email, password) =>  this.auth.signInWithEmailAndPassword(email, password);

    doCheckSSOLogin = (email) => this.fn.httpsCallable('apiAuth-canSSO')({emailAddress: email});

    doSignInWithO365 = (hintEmail) => {
        var provider = new app.auth.OAuthProvider('microsoft.com');
        if (hintEmail && hintEmail !== "") {
            provider.setCustomParameters({
                login_hint: hintEmail
            });
        }
        return this.auth.signInWithRedirect(provider);
    }

    getRedirectResult = () => this.auth.getRedirectResult();
   
    doSignOut = () => this.auth.signOut();
   
    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
   
    doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

    doSendEmailVerification = () => this.auth.currentUser.sendEmailVerification({
        url: ROUTES.EMAIL_VERIFICATION_HOME,
    });

    // *** Merge Auth and DB User API *** //
 
    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged(authUser => {
        if (authUser) {
            this.user(authUser.uid)
                .get()
                .then(doc => {
                     if (doc.exists)
                     {
                         const dbUser = doc.data();
                         // default empty roles
                         if (!dbUser.roles) {
                            dbUser.roles = [];
                         }

                        // merge auth and db user
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            emailVerified: authUser.emailVerified,
                            providerData: authUser.providerData,
                            ...dbUser,
                        };
                    }
                    next(authUser);
                });
        } else {
            fallback();
        }
    });
    
    // *** User API ***

    user = uid => this.fs.collection('users').doc(uid);
    users = () => this.fs.collection('users');

    updateUserToken = () => this.auth.currentUser.getIdToken(true);
                
    // *** Tenant API ***

    tenant = tenantId => this.fs.collection('tenants').doc(tenantId);

    upsertSite = (tenantId, siteDef, action) => {
        const writeValue = { ...siteDef.site };

        const batch = this.fs.batch();
        const tenantRef = this.tenant(tenantId);

        const siteRef = this.sites(tenantId).doc();
        const id = action === DATAACTIONS.EDIT ? siteDef.id : siteRef.id;

        if (action === DATAACTIONS.EDIT) {
            // update the site
            const updateSiteRef = this.sites(tenantId).doc(id);

            batch.update(updateSiteRef, {... writeValue});
        } else {
            // write a new site
            batch.set(siteRef, {...writeValue});
        }

        let siteListObject = {
            name: writeValue.companyName,
            city: writeValue.city,
            state: writeValue.state,
            country: writeValue.country,
            status: writeValue.status,
            acceptingApplications: writeValue.acceptingApplications ?? true,
        };

        batch.update(tenantRef, `siteMap.${id}`, siteListObject);
        batch.commit();
    }

    sites = tenantId => this.fs
                            .collection('tenants')
                            .doc(tenantId)
                            .collection('sites');

    site = (tenantId, siteId) => this.fs
                                    .collection('tenants')
                                    .doc(tenantId)
                                    .collection('sites')
                                    .doc(siteId);

    // ** Questionnaires API

    tenantLevelQuestionnaires = async(tenantId) => this.fs
                                                        .collection('applications')
                                                        .doc(tenantId)
                                                        .collection('tenant-questionnaires')

    questionnaire = (tenantId, questionnaireId) =>
        this.fs.collection('applications').doc(tenantId).collection('tenant-questionnaires').doc(questionnaireId);


    // ** Applications API
    tenantLevelApplications = (tenantId) => this.fs
                                .collection('applications')
                                .doc(tenantId)
                                .collection('tenant-applications');


    tenantLevelApplicationsInfo = (tenantId) => this.fs
                                .collection('applications')
                                .doc(tenantId)
                                .collection('tenant-applications-info');

    application = (tenantId, applicationId) =>
        this.fs.collection('applications').doc(tenantId).collection('tenant-applications').doc(applicationId);

    applicationNotes = (tenantId, applicationId) =>
        this.fs.collection('applications').doc(tenantId).collection('tenant-applications').doc(applicationId).collection('notes');

    applications = (tenantId) =>
        this.fs.collection('applications').doc(tenantId).collection('tenant-applications');

    updateApplicationStatus = async(tenantId, applicationId, status, lastChangedByUid, lastChangedByName) => {
        let batch = this.fs.batch();
        let appInfoRef = this.fs.collection('applications').doc(tenantId).collection('tenant-applications-info').doc(applicationId)
        let appRef = this.fs.collection('applications').doc(tenantId).collection('tenant-applications').doc(applicationId);

       
        batch.update(appInfoRef, {status, lastChangedByUid, lastChangedByName});
        batch.update(appRef, {status, lastChangedByUid, lastChangedByName});

        try {
            await batch.commit();
            return { status, success: true };
        }
        catch(error) {
            return { status, success: false };
        }
    }

    applicationAddNote = (tenantId, applicationId, noteText) => this.fn.httpsCallable('apiApplication-addApplicationNote')({tenantId, applicationId, noteText});

    getSSOLogo = (slug) => {
        let url = `https://us-central1-${config.authDomain.split('.')[0]}.cloudfunctions.net/apiExtApplication-getPublicApplication?slug=${slug}`;
        let defaultVal = { logo: null, title: null };
        fetch(url)
            .then(r => r.json())
            .then(r => {
                if (r != undefined) {
                    let d = JSON.parse(r.definition)
                    return { logo: r.logo, title: d.title}
                }
                return defaultVal;
            })
            .catch(e => {
                return defaultVal;
            });
    }
    
  }
   
export default Firebase;
