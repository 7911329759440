import React, { useState } from 'react';
import AddressFormFields from '../Common/AddressFields';

const YourLocation = (props) => {
    let address = props.fields.address;

    if (props.stepNum !== props.currentStep) {
        return null;
    }

    const onChange = event => {
        if (props.onFieldChange) {
            props.onFieldChange(event);
        }
    }

    return (
        <div className="text-left">
            <div className="form-group">
                <label htmlFor="businessName">Business Name</label>
                <input
                    name="companyName"
                    className="form-control"
                    value={address.companyName}
                    onChange={onChange}
                    maxLength="100"
                    type="text"
                    placeholder="Business Name"
                    required="required"
                />
            </div>

            <AddressFormFields
                address={address}
                onChange={onChange}
                allowInternational={false} />
        </div>
    )
}

export default YourLocation;