import React, { useEffect, useContext, useState } from 'react';
import FirebaseContext from '../../Firebase/context';
import * as ROUTES from '../../../constants/routes';

const SSOSignInCheck = ({authUser, onRequiresRelogin}) => {
    const fbContext = useContext(FirebaseContext);
    const [showIfError, setShowIfError] = useState(false);

    const hasSites = authUser.sites && authUser.sites.length > 0;

    useEffect(() => {
        setTimeout(() => setShowIfError(true), 3500);

        const getRedirectResult = async() => { 
            fbContext.getRedirectResult()
                .then((result) => {
                    if (!result) {
                        if (hasSites) {
                            redirectToApps();
                        } else {
                            console.log("not a redirect");
                            fbContext.doSignOut();
                        }
                        return;
                    }

                    //let credential = result.credential;
                    let user = result.user;
                    if (!user) {
                        fbContext.doSignOut();
                        return;
                    }

                    user.getIdTokenResult()
                        .then((tokenResult) => {
                            if (!tokenResult || tokenResult.claims.tenantId) {
                                redirectToApps();
                                return;
                            }

                            user.getIdTokenResult(true)
                                .then(r => {
                                    if (r.claims.tenantId) {
                                        redirectToApps();
                                    } else {
                                        fbContext.doSignOut();
                                        onRequiresRelogin(user.email);
                                        return;
                                    }
                                })
                        })
               })
        }

        getRedirectResult();
    }, [fbContext, hasSites, onRequiresRelogin]);

    const redirectToApps = () => window.location.href = ROUTES.APPLICANTS;

    return (
        <div className="row">
        <div className="col-8 mx-auto">
            { hasSites && 
                <h4><span className="fa fa-cog fa-spin"></span>&nbsp;Logging in ...</h4>
            }
            { !hasSites &&
                <>
                    <h4><span className="fa fa-cog fa-spin"></span>&nbsp;Finding and associating Sites...</h4>
                    { showIfError &&
                        <p>If you feel that you are seeing this message in error, check with your
                        mysimple<span className='text_brand_primary'>hire</span>&nbsp;
                        administrator to ensure that they have associated a site with your email address.</p>
                    }
                </>
            }
        </div>
    </div>

    )
}

export default SSOSignInCheck;