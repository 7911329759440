import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import CompanyFields from '../Common/CompanyFields';
import { withFirebase } from '../Firebase';

class TenantItemBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            authUser: props.authUser,
            tenant: {
                status: 0,
                companyName: '',
                address1: '',
                address2: '',
                city: '',
                country: 'USA',
                state: '',
                ZIP: ''
        
            },        
        }
    };

    componentDidMount() {
        this.setState({ loading: true });

        if (this.state.authUser.tenantId !== -1) {
            this.unsubscribe = this.props.firebase
            .tenant(this.state.authUser.tenantId)
            .onSnapshot(doc => {
                let tenant =  doc.data();

                if (tenant === undefined) {
                    tenant = {
                        status: 1,
                        companyName: '',
                        address1: '',
                        address2: '',
                        country: 'USA',
                        city: '',
                        state: '',
                        ZIP: ''
                    }   
                }

                this.setState({
                    tenant,
                    loading:false
                })
            })
        }
    }

    componentWillUnmount() {
        if (this.state.authUser.tenantId !== -1) {
            this.unsubscribe();
        }
    }

    onChange = event => {
        const prevState = this.state.tenant;
        this.setState({ tenant: {
            ...prevState,
            [event.target.name]: event.target.value} });
    };

    onSubmit = event => {
        event.preventDefault();
        const { tenant } = this.state;
        this.props.firebase.tenant(this.state.authUser.tenantId)
            .set({
                ...tenant,
                timestamp: this.props.firebase.fsNow(),
            }, { merge: true });

    }

    render() {
        const { loading, authUser } = this.state;
        let tenant = this.state.tenant;
        const isInvalid = !tenant || !tenant.companyName || !tenant.address1 || !tenant.city || !tenant.country
                            || (tenant.country === "USA" && !tenant.state) || !tenant.ZIP;
        return (
            <Card>
                <Card.Header>
                    Parent Company
                </Card.Header>
                <Card.Body>
                    {loading && <div>Loading ...</div>}
                    { authUser.tenant_admin 
                        ? <Form onSubmit={this.onSubmit}>
                            <CompanyFields onChange={this.onChange} company={ this.state.tenant }/>
                            <button className="btn btn-lg btn-primary btn-block" disabled={isInvalid} type="submit">Update</button>
                        </Form>

                        : <div>{tenant.companyName}</div>
                    }
                </Card.Body>
            </Card>
        )
    }
}

const TenantItem = withFirebase(TenantItemBase);

export default TenantItem;