import React from 'react';
import { Switch, Route  } from 'react-router-dom';
import { AuthUserContext, AuthContextRoute, withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import ApplicantItem from './ApplicantItem';
import ApplicantsList from './ApplicantsList';
import Container from 'react-bootstrap/Container';
import * as ROUTES from '../../constants/routes';

const ApplicationsPage = (props) => (
        <div className="wrapper d-flex align-items-stretch">
            <Container fluid>
                    <AuthUserContext.Consumer>
                        {authUser => {
                            let hasSites = authUser.sites && authUser.sites.length > 0;

                            return (
                                <>
                                { !hasSites && 
                                    <div className="row">
                                        <div className="col-4 mx-auto">
                                            <h4><span className="fa fa-cog fa-spin"></span> Finding and associating Sites...</h4>
                                            <p>If you feel that you are seeing this message in error, check with your
                                                mysimple<span className='text_brand_primary'>hire</span>&nbsp;
                                                administrator to ensure that they have associated a site with your email address.</p>
                                        </div>
                                    </div>
                                }
                                { hasSites && 
                                    <Switch>
                                        <Route exact path={ROUTES.APPLICANTS_DETAIL} component={ApplicantItem} />
                                        <AuthContextRoute exact path={ROUTES.APPLICANTS} component={ApplicantsList} authUser={authUser} />
                                    </Switch>
                                }
                                </>
                            )
                        }}
                    </AuthUserContext.Consumer>
            </Container>
        </div>
    );

const condition = authUser => !!authUser;

export default withAuthorization(condition)(withFirebase(ApplicationsPage));