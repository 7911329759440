import React from 'react';
import { Route } from 'react-router-dom';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const currentAuth = () => {
    return JSON.parse(localStorage.getItem('authUser'));
}

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
 
      this.state = {
        authUser: JSON.parse(localStorage.getItem('authUser')),
      };
    }
 
    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
          authUser => {
                localStorage.setItem('authUser', JSON.stringify(authUser));
                this.setState({ authUser });

                // this is so janky. there's likely another event I can listen to rather than this...
                // this should only happen when a new user validates their email for
                if (authUser.providerData.map(provider => provider.providerId).includes('password'))
                {
                    const evLast = "evLast";
                    let evl = localStorage.getItem(evLast);
    
                    let evCurrent = authUser.emailVerified ? "1" : "0";

                    if (!evl || evl === null) {
                        localStorage.setItem(evLast, evCurrent);
                    } else {
                        if (evl === "0" && evCurrent === "1") {
                            this.props.firebase.updateUserToken()
                                .then(() => {
                                    localStorage.setItem(evLast, evCurrent);
                                    // need to update the page to redirect
                                    window.location.href = "/account";
                                });
                        }
                    }    
                }
          },
          () => {
              localStorage.removeItem('authUser');
              this.setState({ authUser: null });
          },
      );
    }
 
    componentWillUnmount() {
      this.listener();
    }
 
    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }
 
  return withFirebase(WithAuthentication);
};

const AuthContextRoute = ({ authUser, component, ...rest }) => {
    const Component = component;
    return (
        <Route>
            <AuthUserContext.Consumer>
            {authUser => <Component authUser={authUser} /> }
            </AuthUserContext.Consumer>
        </Route>
    )
}

export default withAuthentication;

export { AuthContextRoute, currentAuth };