import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { AppStatusOptions } from '../../constants/statuses';

const StatusPopOver = (name, appId, onStatusClicked) => {
    const handleClick = (val) => {
        document.body.click();
        onStatusClicked(name, appId, val);
    }

    return (
    <Popover>
        <Popover.Title as="h4">{name}</Popover.Title>
        <Popover.Content>
            {AppStatusOptions.map((s) => {
                let statusVal = s.value;
                return (
                <div
                    key={statusVal}
                    onClick={() => handleClick(statusVal)}
                    className={`m-1 p-1 badge-status badge-status-${statusVal.toLowerCase().replace(' ',  '-')}`}>
                    <small><strong>{s.label}</strong></small>
                </div>)
            })}
        </Popover.Content>
    </Popover>
)}

const ApplicantStatusSelector = ({status, overlay, placement}) => (
    <OverlayTrigger trigger="click" rootClose overlay={overlay} placement={placement ?? "right-end"}>
        <span className={`p-2 badge badge-pill badge-status badge-status-${status.toLowerCase().replace(' ',  '-')}`}>
                {status}
        </span>
    </OverlayTrigger>
)

export default ApplicantStatusSelector;

export { StatusPopOver }