import React, { useState } from 'react';
import { Button, Col, Container, Nav, NavDropdown, Row } from 'react-bootstrap';
import { SiteList, SiteEditModal, SITE_MODAL_MODE, BLANK_SITE, SITE_CHANGE_TYPE } from '../Site';
import { AuthUserContext, withAuthorization } from '../Session';
import useFileHandling from '../Common/useFileHandling';
import { withFirebase } from '../Firebase';
import { Navbar } from 'react-bootstrap';
import * as DATAACTIONS from '../../constants/dataActions';
 

const SitesPage = (props) => {
    const [show, setShow] = useState(false);
    const [mode, setMode] = useState(SITE_MODAL_MODE.ADD);
    const [selectedSiteDoc, setSiteDoc] = useState(BLANK_SITE);
    const [currentSiteList, setCurrentSiteList] = useState([]);

    const { exportToCSV, exportToJson } = useFileHandling();

    const handleAddClick = () => {
        setMode(SITE_MODAL_MODE.ADD);
        setSiteDoc(BLANK_SITE);
        setShow(true);
    }

    const onSitesLoaded = (data) => {
        if (data) {
            setCurrentSiteList(data);
        }
    }

    const quoteString = (val) => {
        if (!val || val.length === 0) {
            return '""';
        }

        if (val.includes(',')) {
            // escape a double quote with a double double quote
            return `"${val.replace(/"/g, '""')}"`;
        }

        return val;
    }

    const handlExportJsonClick = () => {
        if (exportToJson && currentSiteList && currentSiteList.length > 0) {
            exportToJson(currentSiteList, 'sites.json');
        }
    }

    const handleExportCSVClick = () => {
        if (exportToCSV && currentSiteList && currentSiteList.length > 0) {
            let headers = ['Id,Internal Id,Status,SiteName,Address1,Address2,City,State,ZIP,Country,Phone'];

            let dataCSV = currentSiteList.reduce((acc, siteDoc) => {
                const { id, site } = siteDoc
                acc.push([
                    id,
                    quoteString(site.internalId),
                    site.status,
                    quoteString(site.companyName),
                    quoteString(site.address1),
                    quoteString(site.address2),
                    quoteString(site.city),
                    quoteString(site.state),
                    quoteString(site.ZIP),
                    quoteString(site.country),
                    quoteString(site.phoneNumber),
                    ].join(','))
                return acc
              }, [])
            exportToCSV(headers, dataCSV, 'sites.csv');
        }
    }

    const handleEditClick = (siteDoc, event) => {
        event.preventDefault();
        setMode(SITE_MODAL_MODE.EDIT);
        setSiteDoc(siteDoc);
        setShow(true);
    }

    const handleModalClose = (event) => {
        setShow(false);
    }

    const handleModalSubmit = (event) => {
        event.preventDefault();

        props.firebase.upsertSite(props.tenantId, selectedSiteDoc, mode === SITE_MODAL_MODE.EDIT ? DATAACTIONS.EDIT : DATAACTIONS.ADD);

        setShow(false);
    }

    const handleSiteChange = (changeType, event) => {

        let targetValue = event.target.name === "status" ? parseInt(event.target.value) : event.target.value;

        const site = {
            ...selectedSiteDoc.site,
            [event.target.name]: (changeType === SITE_CHANGE_TYPE.TOGGLE ? event.target.checked : targetValue)
        };

        setSiteDoc({
            ...selectedSiteDoc,
            site
        });
    }

    const handlePrimaryContactMapChange = event => {
        const selectedSite = selectedSiteDoc.site;
        const primaryContact = { ...selectedSite.primaryContact, [event.target.name]: event.target.value };
        const site = { ...selectedSite, primaryContact };
        setSiteDoc({
            ...selectedSiteDoc,
            site
        });
    }

    return (
        <AuthUserContext.Consumer>
        {authUser => (
            <Container fluid>
                <Row>
                    <Col lg={7} className='mx-auto'>
                        <Navbar bg="dark" expand="md" navbar="dark" variant="dark">
                            <Navbar.Brand><h2>Sites</h2></Navbar.Brand>
                            <Navbar.Toggle aria-controls='sitesPageNavbar'/>
                            <Navbar.Collapse id='sitesPageNavbar' className="justify-content-end">
                                <Nav>
                                    { authUser.roles && authUser.tenant_admin &&
                                        <>
                                            <NavDropdown title={<><span className='fa fa-file-text-o'></span> Export</>} id='exportDropdown' className='btn btn-outline-secondary p-0 mr-2'>
                                                <NavDropdown.Item onClick={handleExportCSVClick}>Export to CSV</NavDropdown.Item>
                                                <NavDropdown.Item onClick={handlExportJsonClick}>Export to json</NavDropdown.Item>
                                            </NavDropdown>
                                            <Nav.Link onClick={handleAddClick} className='btn btn-outline-success'>
                                                <span className='fa fa-plus'></span> Add New Site
                                            </Nav.Link>
                                        </>
                                    }
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                        <p className='lead mt-2 p-2'>
                            Sites are the physical location where your applicants will work when hired or the hub that is their primary workplace.
                        </p>

                        { ((authUser.sites && authUser.sites.length > 0) || authUser.tenant_admin)
                            ? <SiteList authUser={authUser} onEditClick={handleEditClick} onSitesLoaded={onSitesLoaded}/>
                            : <div>You have no defined sites</div>
                        }

                        <SiteEditModal
                            show={show}
                            mode={mode}
                            siteDoc={selectedSiteDoc}
                            onSiteChange={handleSiteChange}
                            onChangePrimaryContactMap={handlePrimaryContactMapChange}
                            onSubmit={handleModalSubmit}
                            onClose={handleModalClose}
                            tenant={authUser}/>

                    </Col>
                </Row>
            </Container>
        )}
        </AuthUserContext.Consumer>
);
}

const condition = authUser => !!authUser;

export default withFirebase(withAuthorization(condition)(SitesPage));