const TablePagination = ({gotoPage, previousPage, nextPage, setPageSize, canPreviousPage, canNextPage, pageCount, pageIndex, pageOptions, pageSize}) => {

    return (
        <div className="pagination pull-right">
            <small>
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className='btn btn-sm btn-outline-secondary'>
                {'<<'}
                </button>{' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage} className='btn btn-sm btn-outline-secondary'>
                {'<'}
                </button>{' '}
                <button onClick={() => nextPage()} disabled={!canNextPage} className='btn btn-sm btn-outline-secondary'>
                {'>'}
                </button>{' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className='btn btn-sm btn-outline-secondary'>
                {'>>'}
                </button>
                <span className='text-muted ml-2 mr-2'>
                Page{' '}
                <strong>
                    {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
                </span>
                <select
                value={pageSize}
                onChange={e => {
                    setPageSize(Number(e.target.value))
                }}
                >
                {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                    </option>
                ))}
                </select>
            </small>
        </div>
    )
}

export default TablePagination;