import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PasswordChangeForm from '../PasswordChange';
import TenantItem from '../Tenant';
import { SiteList, SiteEditModal, SITE_MODAL_MODE, BLANK_SITE, SITE_CHANGE_TYPE } from '../Site';
import { AuthUserContext, withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import { Navbar } from 'react-bootstrap';
import * as DATAACTIONS from '../../constants/dataActions';
 

const AccountPage = (props) => {
    const [show, setShow] = useState(false);
    const [mode, setMode] = useState(SITE_MODAL_MODE.ADD);
    const [selectedSiteDoc, setSiteDoc] = useState(BLANK_SITE);

    const handleAddClick = () => {
        setMode(SITE_MODAL_MODE.ADD);
        setSiteDoc(BLANK_SITE);
        setShow(true);
    }

    const handleEditClick = (siteDoc, event) => {
        event.preventDefault();
        setMode(SITE_MODAL_MODE.EDIT);
        setSiteDoc(siteDoc);
        setShow(true);
    }

    const handleModalClose = (event) => {
        setShow(false);
    }

    const handleModalSubmit = (event) => {
        event.preventDefault();

        props.firebase.upsertSite(props.tenantId, selectedSiteDoc, mode === SITE_MODAL_MODE.EDIT ? DATAACTIONS.EDIT : DATAACTIONS.ADD);

        setShow(false);
    }

    const handleSiteChange = (changeType, event) => {
        const site = {
            ...selectedSiteDoc.site,
            [event.target.name]: (changeType === SITE_CHANGE_TYPE.TOGGLE ? event.target.checked : event.target.value)
        };

        setSiteDoc({
            ...selectedSiteDoc,
            site
        });
    }

    const handlePrimaryContactMapChange = event => {
        const selectedSite = selectedSiteDoc.site;
        const primaryContact = { ...selectedSite.primaryContact, [event.target.name]: event.target.value };
        const site = { ...selectedSite, primaryContact };
        setSiteDoc({
            ...selectedSiteDoc,
            site
        });
    }

    return (
        <AuthUserContext.Consumer>
        {authUser => (
            <Container fluid>
                <Row>
                    <Col lg={5} className="mx-auto">
                        <Navbar bg="dark" expand="sm" navbar="dark" variant="dark">
                            <Navbar.Brand><h2>My Account</h2></Navbar.Brand>
                        </Navbar>
                        <Row className="mt-2">
                            <Col md={6} className="mb-2">
                                <Card>
                                    <Card.Header>Account</Card.Header>
                                    <Card.Body>
                                        <div>
                                            <ul>
                                                <li>{authUser.username}</li>
                                                <li>
                                                    {authUser.email}
                                                </li>
                                            </ul>
                                        </div>                                        
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={6}>
                            <PasswordChangeForm />

                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col md={12}>
                                <TenantItem authUser={authUser}/>
                            </Col>
                        </Row>
                        <Row className='mt-2'>
                            <Col md={12}>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )}
        </AuthUserContext.Consumer>
);
}

const condition = authUser => !!authUser;

export default withFirebase(withAuthorization(condition)(AccountPage));