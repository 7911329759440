import React from 'react';
import { withRouter } from 'react-router-dom';
 
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import withEmailVerification from './withEmailVerification';

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        authUser => {
            if (!condition(authUser)) {
                this.props.history.push(ROUTES.SIGN_IN);
            }
        },
        () => this.props.history.push(ROUTES.SIGN_IN),
      );
    }
 
    componentWillUnmount() {
      this.listener();
    }
 
    render() {
      return (
          <AuthUserContext.Consumer>
              { authUser => condition(authUser) ?  <Component {...this.props} tenantId={authUser.tenantId} /> : null }
          </AuthUserContext.Consumer>
      );
    }
  }
 
    return withRouter(withEmailVerification(withFirebase(WithAuthorization)));
}

export default withAuthorization;