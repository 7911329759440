import React from 'react';


const Question = ({questions}) => {
    // our base case, if we have no items, render nothing.
    if (!questions)
        return null;

    return questions.map(question => (
        <section key={question.name}>
                <div className={question.elements ? "questionContainer panelContainer" : "questionContainer"}>
                        {question.title ?? question.name}
                        {question.description ? <span><br/>{question.description}</span> : ""}
                        {(question.elements 
                            ? <Question questions={question.elements}/>
                            : "")}
                </div>            
        </section>
    ))
}
  
const QuestionnaireItem = (props) => {
    let questionnaire = props.location.state.questionnaire;
    let questionnaireJSON = JSON.parse(questionnaire.definition);
    return (
            <div>
                <h2>Questionnaire</h2>
                <div>
                    <ul className="list-group">
                        <li className="list-group-item">
                            <strong>{questionnaireJSON["title"]}</strong>
                        </li>
                        <li className="list-group-item">
                        {
                            Object.entries(questionnaireJSON.pages).map(([key, value]) => (
                                <div>Page: {value["title"]}
                                <Question questions={value.elements}/>
                                </div>
                            ))
                        }
                        </li>
                    </ul>
                </div>
            </div>
    );
}

export default QuestionnaireItem;