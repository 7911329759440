import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import { SignUpLink } from '../SignUp';
import { PasswordForgotLink } from '../PasswordForgot';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import * as ROUTES from '../../constants/routes';

import styles from './SignInForm.module.scss';

const SignInPage = ({ authUser }) => (

    <AuthUserContext.Consumer>
    { authUser => authUser
        ? <Redirect to={ROUTES.APPLICANTS}/>
        : 
        <div className="text-center">
            <SignInForm/>
            <a className="btn btn-link" href={ROUTES.SSO_SIGN_IN}>Sign in with Company Credentials (SSO)</a>
        {
        <div className="form-signin-links">
            <SignUpLink/>
        </div>
        }
    </div>

    }
    </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
    
}

class SignInFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        event.preventDefault();

        const { email, password } = this.state;
        this.props.firebase
            .doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.APPLICANTS);
            })
            .catch(error => {
                console.log(error);
                this.setState({ error });
            })
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, password, error } = this.state;

        const isInvalid = password === '' || email === '' || email === undefined || password === undefined;

        return (
            <div className="h-100 mt-5">
                <div className="d-flex justify-content-center h-100">
                    <div className={styles.user_card}>
                        <div className="d-flex justify-content-center">
                            <div className={styles.brand_logo_container}>
                                <img src="/img/msh-logo-150x150.png" className="brand_logo" alt="Logo" />
                            </div>
                        </div>
                        <div className={`d-flex justify-content-center ${styles.form_container}`}>
                            <form
                                onSubmit={this.onSubmit}
                                >
                                <h2 className={`h3 mb-3 ${styles.login_title}`}>mysimple<span className={styles.text_brand_primary}>hire</span></h2>
                                <label htmlFor="email" className="sr-only">Email Address</label>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                    <span className={`input-group-text ${styles.bg_brand_accent}`}><i className="fa fa-user"></i></span>
                                    </div>
                                    <input
                                        name="email"
                                        className={`form-control ${styles.input_user}`}
                                        value={email}
                                        onChange={this.onChange}
                                        type="text"
                                        placeholder="Email Address"
                                        required="required"
                                        autoFocus="autoFocus"
                                    />
                                </div>

                                <label htmlFor="password" className="sr-only">Password</label>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend" style={{maxHeight: 46}}>
                                        <span className={`input-group-text ${styles.bg_brand_accent}`}><i className="fa fa-key"></i></span>
                                    </div>
                                    <input
                                        name="password"
                                        className="form-control input_pass"
                                        value={password}
                                        onChange={this.onChange}
                                        type="password"
                                        placeholder="Password"
                                        required="required"
                                    />
                                </div>
                                <button className={`btn btn-lg btn-block ${styles.cta_button}`} disabled={isInvalid} type="submit">
                                Sign In
                                </button>
                        
                                {error && <p className="text-light mt-3">{error.message}</p>}
                                <PasswordForgotLink/>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const SignInForm = withRouter(withFirebase(SignInFormBase));

export default SignInPage;

export { SignInForm };