import React from 'react';
import styles from './mysimplehireContainer.module.scss';

const MSHContainer = (props) => {

   return (
        <div className="h-100 mt-5">
            <div className="d-flex justify-content-center h-100">
                <div className={styles.user_card}>
                    <div className="d-flex justify-content-center">
                        <div className={styles.brand_logo_container}>
                            <img src="/img/msh-logo-150x150.png" className="brand_logo" style={{maxHeight:65}} alt="Logo" />
                        </div>
                    </div>
                    <div className={`d-block justify-content-center ${styles.form_container}`}>
                    {props.children}
                    </div>
                </div>
            </div>
        </div>)
}

export default MSHContainer;