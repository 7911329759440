import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup'
import CompanyFields from '../Common/CompanyFields';

function isValidSite(site) {
    return !(site === undefined || site.companyName === '' || site.primaryContact?.emailAddress === '');
}

const SiteItemForm = props => {
    return (
        <>
        <div className="container">
            <Form.Group>
                <Form.Check
                    type="switch"
                    checked={props.site.acceptingApplications}
                    id="acceptingApplications"
                    name="acceptingApplications"
                    onChange={props.onToggle}
                    label="Accepting Applications?"
                    />
            </Form.Group>
            <Form.Group>
                <Form.Label>Send <strong>New Application Received</strong> Notification Email to:</Form.Label>
                <InputGroup>
                    <Form.Control
                        type="email"
                        value={props.site.notificationEmailAddress}
                        name="notificationEmailAddress"
                        placeholder="you@domain.com"
                        required
                        onChange={props.onChange}
                        />
                        <InputGroup.Append>
                            <InputGroup.Text><i className="fa fa-envelope"></i></InputGroup.Text>
                        </InputGroup.Append>

                </InputGroup>
            </Form.Group>
            <hr className="bg_brand_accent"/>
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <CompanyFields onChange={props.onChange} company={ props.site }/>
                    <Form.Row>
                        <Form.Group className="col-sm-12 col-md-6">
                            <Form.Label>Site Email Address</Form.Label>
                            <Form.Control
                                type="text"
                                value={props.site.emailAddress}
                                name="emailAddress"
                                placeholder="siteemail@domain.com"
                                onChange={props.onChange}
                            />
                        </Form.Group>

                        <Form.Group className="col-sm-12 col-md-6">
                            <Form.Label>Site Phone #</Form.Label>
                            <Form.Control
                                type="text"
                                value={props.site.phoneNumber}
                                name="phoneNumber"
                                placeholder="###-###-#### / include +country code for internationals"
                                onChange={props.onChange}
                            />
                        </Form.Group>
                    </Form.Row>
                </div>
                <div className="col-sm-12 col-md-6">
                    <Form.Row>
                        <Form.Group className="col-sm-12 col-md-6">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                as="select"
                                name="status"
                                value={props.site.status}
                                onChange={props.onChange}
                                required>
                                    <option value="2">Coming Soon</option>
                                    <option value="1">Open</option>
                                    <option value="0">Closed</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="col-sm-12 col-md-6">
                            <Form.Label>
                                Internal ID
                                <OverlayTrigger placement="auto"
                                    overlay={<Tooltip>Optional. If your organizations has an internal number or identifier your sites, you can enter it here.</Tooltip>}>
                                    <sup><span className='fa fa-question-circle text-secondary'></span></sup>
                                </OverlayTrigger>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={props.site.internalId}
                                name="internalId"
                                placeholder="Optional Internal Identifier"
                                onChange={props.onChange}
                            />
                        </Form.Group>
                    </Form.Row>

                    <div className='card'>
                        <div className='card-header'>Primary Contact</div>
                        <div className='card-body'>
                            <Form.Row>
                                <Form.Group className="col-sm-12 col-lg-8">
                                    <Form.Label>Name</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type="text"
                                            value={props.site.primaryContact.displayName}
                                            name="displayName"
                                            placeholder="Owner/Director/Manager name"
                                            onChange={props.onUpdateMap}
                                            />
                                        <InputGroup.Append>
                                            <InputGroup.Text><i className="fa fa-user"></i></InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group className="col-sm-12 col-lg-4">
                                    <Form.Label>Title</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type="text"
                                            value={props.site.primaryContact.title}
                                            name="title"
                                            placeholder="Owner/Director/Manager"
                                            onChange={props.onUpdateMap}
                                            />
                                        <InputGroup.Append>
                                            <InputGroup.Text><i className="fa fa-id-card-o"></i></InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>
                            </Form.Row>
                            <Form.Group>
                                <Form.Label>Email Address</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="email"
                                        value={props.site.primaryContact.emailAddress.toLowerCase()}
                                        name="emailAddress"
                                        placeholder="you@domain.com"
                                        required
                                        onChange={props.onUpdateMap}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text><i className="fa fa-envelope"></i></InputGroup.Text>
                                        </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        </div>
                    </div>                    
                </div>
            </div>
        </div>
        </>
    );
}

export default SiteItemForm;

export { isValidSite };