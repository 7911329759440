import React from 'react';
 
import { withFirebase } from '../Firebase';
 
const SignOutButtonBase = ({ firebase }) => (
  <button className="btn btn-dark" onClick={firebase.doSignOut}>Sign Out</button>
);


const SignOutButton =  withFirebase(SignOutButtonBase);

export { SignOutButton };