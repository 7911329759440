import React from 'react';
import { Switch, Route  } from 'react-router-dom';
import { withAuthorization } from '../Session';
import { UserList, UserItem } from '../Users';

import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';
 
const AdminPage = () => (
    <Switch>
        <Route exact path={ROUTES.ADMIN_USER_DETAILS} component={UserItem} />
        <Route exact path={ROUTES.ADMIN} component={UserList} />
    </Switch>
);

const condition = authUser => 
    authUser && !!authUser.roles.includes(ROLES.ADMIN);


export default withAuthorization(condition)(AdminPage);