import React, { Component } from 'react';
import { Link } from 'react-router-dom';
 
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import styles from './PasswordForgot.module.scss';

const PasswordForgotPage = () => (
  <div>
    <PasswordForgotForm />
  </div>
);
 
const INITIAL_STATE = {
  email: '',
  error: null,
  showInput: '',
  showSuccess: 'd-none'
};
 
class PasswordForgotFormBase extends Component {
  constructor(props) {
    super(props);
 
    this.state = { ...INITIAL_STATE };
  }
 
  onSubmit = event => {
    const { email } = this.state;
 
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({
            ...INITIAL_STATE,
            email,
            showInput: 'd-none',
            showSuccess: ''
        });
      })
      .catch(error => {
        this.setState({
            ...INITIAL_STATE,
            error
         });
      });
 
    event.preventDefault();
  };
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
 
  render() {
    const { email, error, showInput, showSuccess } = this.state;
 
    const isInvalid = email === '';
 
    return (
        <div className="h-100 mt-5">
        <div className="d-flex justify-content-center h-100">
            <div className={styles.user_card}>
                <div className="d-flex justify-content-center">
                    <div className={styles.brand_logo_container}>
                        <img src="/img/msh-logo-150x150.png" className="brand_logo" alt="Logo" />
                    </div>
                </div>
                <div className={`d-flex justify-content-center ${styles.form_container}`}>
                    <form onSubmit={this.onSubmit}>
                        <h2 className={`h3 mb-3 ${styles.login_title}`}>mysimple<span className={styles.text_brand_primary}>hire</span></h2>
                        <p className="text-light lead">Forgot Password</p>
                        <label htmlFor="email" className="sr-only">Email Address</label>

                        <div className={showInput}>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                <span className={`input-group-text ${styles.bg_brand_accent}`}><i className="fa fa-user"></i></span>
                                </div>
                                <input
                                    name="email"
                                    className={`form-control ${styles.input_user}`}
                                    value={this.state.email}
                                    onChange={this.onChange}
                                    type="text"
                                    placeholder="Email Address"
                                    required="required"
                                    autoFocus="autoFocus"
                                />
                            </div>

                            <button disabled={isInvalid} className={`btn btn-lg btn-block mt-3 ${styles.cta_button}`} type="submit">
                            Reset My Password
                            </button>
                        </div>
                        <div className={showSuccess}>
                            <p className="text-light">
                                A password reset email has been sent to {this.state.email} and should arrive shortly. 
                                If you don't see the email, please check your
                                    {' '}<strong>Promotions</strong>,
                                    {' '}<strong>Junk</strong>, or
                                    {' '}<strong>Spam</strong> folders for the message.
                            </p>
                        </div>
                        {error && <p>{error.message}</p>}
                    </form>
                </div>
            </div>
        </div>
    </div>
    );
  }
}
 
const PasswordForgotLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGOT} className="btn btn-link text-light"><small>Forgot Password?</small></Link>
  </p>
);
 
export default PasswordForgotPage;
 
const PasswordForgotForm = withFirebase(PasswordForgotFormBase);
 
export { PasswordForgotForm, PasswordForgotLink };