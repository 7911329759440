import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { ColumnHeaderFilter } from '../Common/ColumnHeaderFilter';
import TablePagination from "../Common/TablePagination";
import { withFirebase } from '../Firebase';
import { useTable, useFilters, usePagination, useSortBy } from 'react-table';

const siteListColumns = 
[
    {
        Header: 'Sites',
        columns: [
            {
                Header: 'Name',
                accessor: 'site.companyName',
            },
            {
                Header: 'Primary Contact',
                accessor: 'site.primaryContact.displayName'
            },
            {
                Header: 'State',
                accessor: 'site.state'
            }
        ]
    }
];

const SiteListTable = ({ columns, data, onEditClick}) => {

    const defaultColumn = React.useMemo(
        () => ({
            // Default Filter UI
            Filter: ColumnHeaderFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data,
        defaultColumn,
        autoResetPage: false,
        autoResetFilters: false,
        autoResetSortBy: false
    },
    useFilters,
    useSortBy,
    usePagination)

    const siteStatus = (statusVal) => {
        switch (statusVal) {
            case 1:
                return { color: "rgb(90, 192, 90)", alt: 'Open' };
            case 2:
                return { color: "rgb(253, 216, 0)", alt: 'Coming Soon'};
            case 0:
                return { color: "rgb(0, 0, 0)", alt: 'Closed' };
            default:
                return { color: "rgb(220,220,220)", alt: 'Unknown'};
        }
    }

    // Render the UI for your table
    return (
    <>
        <table {...getTableProps()} className="table table-hover table-striped">
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        <th>&nbsp;</th>
                        {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps()}>
                            <span {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')} </span>
                            <span>
                            {column.isSorted
                                ? column.isSortedDesc
                                    ? <span className="fa fa-sort-alpha-desc ml-2 text-danger"></span>
                                    : <span className="fa fa-sort-alpha-asc ml-2 text-success"></span>
                                : ''}
                            </span>
                            <div className='d-none d-sm-block'>{column.canFilter ? column.render('Filter') : null}</div>
                        </th>
                        ))}
                        <th>&nbsp;</th>
                    </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row)
                    let thisSiteStatus = siteStatus(row.original.site.status);

                    return (
                        <tr {...row.getRowProps()}>
                            <td><span className="fa fa-circle ml-1 mr-2" style={{color: thisSiteStatus.color}} title={thisSiteStatus.alt}></span></td>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>
                                    
                                    {cell.render('Cell')}
                                </td>
                            })}
                            <td>
                            <Button variant="success" className="m-1" size="sm" onClick={(e) => onEditClick(row.original, e)}>
                                <span className='fa fa-pencil'></span>
                            </Button>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>

        <TablePagination
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                setPageSize={setPageSize}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                pageSize={pageSize}
            />
    </>
  )
}

const SiteListBase = (props) => {

    const [isLoading, setLoading] = useState(false);
    const [siteDocs, setsiteDocs] = useState([]);

    useEffect(() => {
        setLoading(true);

        let query = props.firebase
                        .sites(props.authUser.tenantId)
                        .orderBy('state');

        const unsubscribe =
            query
            .onSnapshot(snapshot => {
                let sDocs = [];
                snapshot.forEach(doc => {
                    let sites = props.authUser.sites;
                    if (props.authUser.tenant_admin || (sites && sites.includes(doc.id)))
                    {
                        let data = doc.data();

                        if (!data.country || data.country === "") {
                            data = {
                                ...doc.data(),
                                "country": "USA"
                            }
                        }

                        if (!data.notificationEmailAddress) {
                            data = {
                                ...data,
                                "notificationEmailAddress": data.primaryContact.emailAddress
                            }
                        }

                        if (!data.phoneNumber) {
                            data = {
                                ...data,
                                "phoneNumber": ''
                            }
                        }

                        sDocs.push( {
                            id: doc.id,
                            site: {
                                ...data
                            }
                        });
                    }
                })

                setsiteDocs(sDocs);
                if (props.onSitesLoaded) {
                    props.onSitesLoaded([... sDocs]);
                }
                setLoading(false);
            })
        

        return () => {
            unsubscribe();
        }

    }, [props.authUser.sites]);
    


    return (
        <div>                 
            {siteDocs
                ? <SiteListTable columns={siteListColumns} data={siteDocs} onEditClick={props.onEditClick}/>
                : <div>No sites are assigned to this tenant.</div>
            }
            {isLoading && <div>Loading...</div>}
        </div>
    );
}

const SiteList = withFirebase(SiteListBase);

export default SiteList;