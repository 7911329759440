import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

import styles from '../SignUp/SignUpForm.module.scss';

const withEmailVerification = Component => {
    class WithEmailVerification extends React.Component {
        constructor(props) {
            super(props);
            this.state = { isSent: false };
        }

        onSendEmailVerification = () => {
            this.props.firebase
                .doSendEmailVerification()
                .then(() => this.setState({ isSent: true }));
        }
        
        render() {
            return (
                <AuthUserContext.Consumer>
                    {authUser =>
                        needsEmailVerification(authUser) ? (
                            <div>
                                <div className="h-100 mt-5">
                                    <div className="d-flex justify-content-center h-100">
                                        <div className={styles.user_card}>
                                            <div className="d-flex justify-content-center">
                                                <div className={styles.brand_logo_container}>
                                                    <img src="/img/msh-logo-150x150.png" className="brand_logo" alt="Logo" />
                                                </div>
                                            </div>
                                            <div className={`${styles.form_container} text-left`}>
                                                <h3>{this.state.isSent ? "Email Confirmation Sent" : "Check Your Email" }</h3>
                                                <p>We sent an email confirmation to {authUser.email}!</p>
                                                <p>Please check your email and click the link.</p>
                                                <p>Once you verify your email address, refresh this page and
                                                    we'll move on to setting up your first location to start accepting applications.</p>
                                                <p><small><strong>NOTE:</strong> You may need to check your Spam or Junk folder for this email.</small></p>

                                                <div className="text-center mt-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={this.onSendEmailVerification}
                                                        disabled={this.state.isSent}
                                                    >
                                                        Resend Email Confirmation <span className="fa fa-envelope"></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Component {...this.props}/>
                        )
                    }
                </AuthUserContext.Consumer>
            );
        }
    }

    return withFirebase(WithEmailVerification);
}

const needsEmailVerification = authUser => 
    authUser
    && !authUser.emailVerified
    && authUser.providerData
         .map(provider => provider.providerId)
         .includes('password');

export default withEmailVerification;