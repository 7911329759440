const availabilityAnswer = (day, answer) => answer[day].shifts ?? `${answer[day].start} to ${answer[day].end.replace(/\s/g,'\u00a0')}`;

export default (answer) => {
    return (
        <div className="row">
            <div className="d-none d-md-block col-md-12">
                <table border='0' className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Sunday</th>
                            <th>Monday</th>
                            <th>Tuesday</th>
                            <th>Wednesday</th>
                            <th>Thursday</th>
                            <th>Friday</th>
                            <th>Saturday</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{availabilityAnswer("Sunday", answer)}</td>
                            <td>{availabilityAnswer("Monday", answer)}</td>
                            <td>{availabilityAnswer("Tuesday", answer)}</td>
                            <td>{availabilityAnswer("Wednesday", answer)}</td>
                            <td>{availabilityAnswer("Thursday", answer)}</td>
                            <td>{availabilityAnswer("Friday", answer)}</td>
                            <td>{availabilityAnswer("Saturday", answer)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="col-12 d-md-none">
                <table border='0' className="table table-striped">
                    <tbody>
                        <tr>
                            <td className="font-weight-bold">Sunday</td>
                            <td>{availabilityAnswer("Sunday", answer)}</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Monday</td>
                            <td>{availabilityAnswer("Monday", answer)}</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Tuesday</td>
                            <td>{availabilityAnswer("Tuesday", answer)}</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Wednesday</td>
                            <td>{availabilityAnswer("Wednesday", answer)}</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Thursday</td>
                            <td>{availabilityAnswer("Thursday", answer)}</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Friday</td>
                            <td>{availabilityAnswer("Friday", answer)}</td>
                        </tr>
                        <tr>
                            <td className="font-weight-bold">Saturday</td>
                            <td>{availabilityAnswer("Saturday", answer)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}