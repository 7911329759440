import React from 'react';
import { Switch, Route  } from 'react-router-dom';
import { AuthUserContext, AuthContextRoute, withAuthorization } from '../Session';
import QuestionnaireItem from './questionnaireItem';
import QuestionnairesList from './questionnaireList';
import Container from 'react-bootstrap/Container';


import * as ROUTES from '../../constants/routes';
 
const QuestionnairePage = ({ authUser }) => (
    <div className="wrapper d-flex align-items-stretch">
        <Container fluid>
                <AuthUserContext.Consumer>
                    {authUser =>
                        (authUser.sites || authUser.tenant_admin)
                            ?
                            <>
                            <Switch>
                                <Route exact path={ROUTES.APPLICATIONFORMS_DETAIL} component={QuestionnaireItem} />
                                <AuthContextRoute exact path={ROUTES.APPLICATIONFORMS} component={QuestionnairesList} authUser={authUser} />
                            </Switch>
                            </>
                            : <div>You don't have explicit access to any sites</div>
                    }
                </AuthUserContext.Consumer>
        </Container>
    </div>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(QuestionnairePage);