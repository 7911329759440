import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { withFirebase } from '../Firebase';
 
const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};
 
class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);
 
    this.state = { ...INITIAL_STATE };
  }
 
  onSubmit = event => {
    const { passwordOne } = this.state;
 
    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
 
    event.preventDefault();
  };
 
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
 
  render() {
    const { passwordOne, passwordTwo, error } = this.state;
 
    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';
 
    return (
        <Card>
            <Card.Header>Change My Password</Card.Header>
            <Card.Body>
                <Form onSubmit={this.onSubmit}>
                    <Form.Group>
                        <Form.Label>New Password</Form.Label>
                        <input
                            name="passwordOne"
                            value={passwordOne}
                            onChange={this.onChange}
                            type="password"
                            placeholder="New Password"
                            className="form-control"
                        />
                    </Form.Group>
                    <Form.Group>
                        <input
                        name="passwordTwo"
                        value={passwordTwo}
                        onChange={this.onChange}
                        type="password"
                        placeholder="Confirm New Password"
                        className="form-control"
                    />
                    </Form.Group>
                <button disabled={isInvalid} type="submit" className='btn btn-warning pull-right'>
                    Reset My Password
                </button>

                {error && <p>{error.message}</p>}
                </Form>
            </Card.Body>
        </Card>
    );
  }
}
 
export default withFirebase(PasswordChangeForm);