import React, { useState, Component  } from 'react';
import { Switch, Route  } from 'react-router-dom';
import { withFirebase } from '../../Firebase';
import { withRouter } from 'react-router-dom';
import { AuthUserContext } from '../../Session';
import SSOSignInCheck from './SSOSignInCheck';
import styles from '../SignInForm.module.scss';
import * as ROUTES from '../../../constants/routes';

const SSOSignIn = () => (
    <Switch>
        <Route exact path={ROUTES.SSO_SIGN_IN} component={SSOSignInTarget} />
        <Route exact path={ROUTES.SSO_SIGN_IN_BRANDED} component={BrandedSSOSignIn} />
    </Switch>
);

const BrandedSSOSignInBase = (props) => {
    console.log(props.firebase);
    let dq = props.firebase.getSSOLogo(props.match.params.id);
    
    console.log(dq);

    return (
        <div>
            branded {props.match.params.id}
        </div>
    )
}

const SSOSignInTarget = () => {
    const [requiresRelogin, setRequiresReLogin] = useState(false);
    const [reloginEmailAddress, setReloginEmailAddress] = useState(false);

    const handleRequiresRelogin = (emailAddress) => {
        setRequiresReLogin(true);
        setReloginEmailAddress(emailAddress);
    }

    return (
        <AuthUserContext.Consumer>
        { authUser => authUser
            ? <SSOSignInCheck authUser={authUser} onRequiresRelogin={handleRequiresRelogin}/>
            : 
            <div className="text-center">
                <SSOSignInForm
                    requiresRelogin={requiresRelogin}
                    reloginEmailAddress={reloginEmailAddress}
                    onResetRequiresLogin={() => setRequiresReLogin(false)}/>
                <a className="btn btn-link" href={ROUTES.SIGN_IN}>Sign in with username/password</a>
        </div>
        }
        </AuthUserContext.Consumer>
    )
}

class SSOSignInFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAddress: "",
            error: "",
            disabled: false,
            loading: (window.location.hash === "#redirecting"),
            requiresRelogin: props.requiresRelogin,
            reloginEmailAddress: props.reloginEmailAddress,
        }
    }

    componentDidMount() {
        if (window.location.hash === "#redirecting") {
            this.setState({disabled: false});
            window.location.hash = "";
        }
    }

    onStartSignIn = () => {
        const { emailAddress, requiresRelogin, reloginEmailAddress } = this.state;
        this.setState({error: ""});
        this.setState({disabled: true });
        this.props.firebase.doCheckSSOLogin(requiresRelogin ? reloginEmailAddress : emailAddress)
            .then((result) => {
                const signInResult = result.data;
                if (!signInResult.isValid) {
                    this.setState({error: "That email address is not Single Sign On enabled."});
                    this.setState({disabled: false});
                } else {
                    if (signInResult.connector === "O365") {
                        window.location.hash = "redirecting";
                        this.props.firebase.doSignInWithO365(emailAddress);
                    } else {
                        this.setState({error: "Unsupported Single Sign On. Please contact support."});
                        this.setState({disabled: false});
                    }
                }
            })
            .catch((error) => {
                this.setState({ error });
                this.setState({disabled: false});
            })
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render () {
        const { emailAddress, error, disabled, loading, requiresRelogin } = this.state;
        const isInvalid = !requiresRelogin && (emailAddress === undefined || emailAddress === '' || disabled);
        return (
            <div className="h-100 mt-5">
                <div className="d-flex justify-content-center h-100">
                    <div className={styles.user_card}>
                        <div className="d-flex justify-content-center">
                            <div className={styles.brand_logo_container}>
                                <img src="/img/msh-logo-150x150.png" className="brand_logo" alt="Logo" />
                            </div>
                        </div>
                        <div className={`d-flex justify-content-center ${styles.form_container}`}>
                            <div>
                                <h2 className={`h3 mb-3 ${styles.login_title}`}>mysimple<span className={styles.text_brand_primary}>hire</span></h2>
                                <p className="lead text-light"><strong>Single Sign On</strong></p>
                                { requiresRelogin && 
                                    <p className="text-light"><strong>Account Creation Complete</strong>
                                    <br/>Click Complete Login button to Continue.</p>
                                }
                                { !requiresRelogin && 
                                    <>
                                    <p className="text-light">
                                        Use your Company Provided Credentials to Sign in
                                    </p>
                                        { !loading && 
                                        <>
                                            <label htmlFor="email" className="sr-only">Email Address</label>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                <span className={`input-group-text ${styles.bg_brand_accent}`}><i className="fa fa-user"></i></span>
                                                </div>
                                                <input
                                                    name="emailAddress"
                                                    className={`form-control ${styles.input_user}`}
                                                    value={emailAddress}
                                                    onChange={this.onChange}
                                                    type="text"
                                                    placeholder="Email Address"
                                                    required="required"
                                                    autoFocus="autoFocus"
                                                    disabled={loading}
                                                />
                                            </div>
                                        </>
                                        }
                                    </>
                                }
                                <button
                                    className={`btn btn-lg btn-block ${styles.cta_button}`}
                                    onClick={this.onStartSignIn}
                                    disabled={isInvalid || loading}>
                                        { !disabled && requiresRelogin && 
                                            <>Complete Login</>
                                        }
                                        { loading &&
                                            <span className="fa fa-spinner fa-spin"></span>
                                        }
                                        { !disabled && !loading && !requiresRelogin &&
                                            <>Start Sign In</>
                                        }
                                        { disabled && 
                                            <>
                                            <span className="fa fa-spinner fa-spin"></span>&nbsp;Launching Log In »
                                            </>
                                        }
                                </button>
                        
                                { error !== '' &&
                                    <p className="text-light mt-3"><span className="fa fa-exclamation-triangle"></span> Error: {error}</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const SSOSignInForm = withRouter(withFirebase(SSOSignInFormBase));

const BrandedSSOSignIn = withFirebase(BrandedSSOSignInBase)

export default SSOSignIn;
