const useFileHandling = () => {

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
      
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
      }

      const exportToJson = (data, fileName) => {
        downloadFile({
          data: JSON.stringify(data),
          fileName,
          fileType: 'text/json',
        })
      }

      const exportToCSV = (headers, dataCSV, fileName) => {      
        downloadFile({
          data: [...headers, ...dataCSV].join('\n'),
          fileName,
          fileType: 'text/csv',
        })
      }

      return {
        exportToJson,
        exportToCSV
      }

}

export default useFileHandling;