import React from "react";


function ColumnHeaderFilter({ column: { filterValue, preFilteredRows, setFilter }}) {
    const count = preFilteredRows.length

    const clearFilterValue = () => {
        setFilter(undefined);
    }

    return (
        <div className="input-group">
            <input
                className="form-control"
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
                placeholder={`Search ${count} records...`}
            />
            <div className="input-group-prepend">
                <button
                    className="btn btn-outline-danger"
                    onClick={clearFilterValue}
                    >
                        ×
                </button>
            </div>
        </div>
    )
}

export { ColumnHeaderFilter };