import React, { useMemo } from "react";
import { Link } from 'react-router-dom';
import { useTable, usePagination, useSortBy } from 'react-table'
import * as ROUTES from '../../constants/routes';
import ApplicantStatusSelector, { StatusPopOver } from './ApplicantStatusSelector';
//import styles from './Applications.module.scss';
import TablePagination from "../Common/TablePagination";
import moment from 'moment';

function Table({ columns, data, authUser, onApplicationStatusChange }) {

    let showSites = ((authUser.sites?.length ?? 0) > 1 || authUser.tenant_admin);
    let hideColumns = showSites ? [] : [ 'siteNameColumn' ];

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                hiddenColumns: hideColumns
            },
            autoResetPage: false
        },
        useSortBy,
        usePagination,
    )
    
    const handleStatusChanged = (name, appId, status) => {
        onApplicationStatusChange(name, appId, status);
    }


    return (
        <div className="col-xs-12 col-sm-10 col-xl-8 mx-auto">
            <table className="table table-striped table-hover" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className={ column.className ?? ""}>
                                    <span {...column.getHeaderProps(column.getSortByToggleProps())}>{ column.render('Header') } </span>
                                    <span>
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? <span className="fa fa-sort-alpha-desc ml-2 text-danger"></span>
                                            : <span className="fa fa-sort-alpha-asc ml-2 text-success"></span>
                                        : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        let application = row.original;
                        return (
                            <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                if (cell.column.Header === "Applicant") {
                                    return <td {...cell.getCellProps()} className={cell.column.className ?? ""}>
                                                    <Link to={{pathname: `${ROUTES.APPLICANTS}/${application.id}`,
                                                        state: {
                                                            applicationId: application.id
                                                        }}}>
                                                            {cell.render('Cell')}
                                                    </Link>
                                                    <div className="d-lg-none mt-2">
                                                        { showSites &&
                                                            <div><span className="fa fa-map-marker"></span>&nbsp;{ application.targetLocation }</div>
                                                        }
                                                        <div className="mt-2">
                                                            <ApplicantStatusSelector
                                                                status={application.status}
                                                                overlay={StatusPopOver(application.fullName, application.id, handleStatusChanged)}
                                                            />
                                                        </div>
                                                    </div>
                                            </td>
                                } else if (cell.column.Header === "Status") {
                                    return <td {...cell.getCellProps()}  className={cell.column.className ?? ""}>
                                                <ApplicantStatusSelector
                                                    status={application.status}
                                                    overlay={StatusPopOver(application.fullName, application.id, handleStatusChanged)}
                                                />
                                            </td>
                                } else {
                                    return <td {...cell.getCellProps()} className={cell.column.className ?? ""}>{cell.render('Cell')}</td>
                                }
                            })}
                        </tr>                        )
                    })}
                </tbody>
            </table>

            <TablePagination
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                setPageSize={setPageSize}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                pageSize={pageSize}
            />
        </div>
    )
}

const ApplicantTable = ({data, authUser, onApplicationStatusChange}) => {
    const localDateTime = (display, rec) => {
        let displayMS = display ? display.seconds * 1000 : null;
        return Date.parse(new Date(displayMS ?? rec));
    }
    
    const columns = useMemo(
        () => [
            {
                Header: ' ',
                columns: [
                    {
                        id: 'siteNameColumn',
                        Header: 'Site',
                        accessor: 'targetLocation',
                        className: 'd-none d-lg-block'
                    },
                    {
                        Header: 'Applicant',
                        accessor: 'fullName',
                        className: 'applicantTable-applicant'
                    },
                    {
                        Header: 'Status',
                        accessor: 'status',
                        className: 'd-none d-lg-block'
                    },
                    {
                        Header: 'Applied',
                        Cell: d => {
                            let newDate = new Date(localDateTime(d.cell.row.original.applicationDate, d.cell.row.original.receivedDate));
                            return moment(newDate).format('lll');
                        },
                        accessor: d => localDateTime(d.applicationDate, d.receivedDate)
                    }
                ]
            },
        ],
        []
    )

    return (
        <Table columns={columns} data={data} authUser={authUser} onApplicationStatusChange={onApplicationStatusChange} />
    )
}

export default ApplicantTable;