import React, { ReactNode } from 'react';

// adapted from https://fellowtuts.com/bootstrap/off-canvas-sidebar-menu-wordpress/
const OffCanvasSidebar = ({show, title, children, onClose}) => {
    const doClose = () => {
        onClose();
    }

    const defaultClasses = 'bs-canvas bs-canvas-right position-fixed bg-light subtle-box-shadow h-100';

    return (
        <div className={defaultClasses + (show ? ' mr-0' : '')}>
	        <header className="bs-canvas-header p-3 bg-secondary overflow-auto">
    	        <button
                    type="button"
                    className="bs-canvas-close float-left close"
                    onClick={doClose}
                    aria-label="Close"><span aria-hidden="true" className="text-light">&times;</span></button>
                <h4 className="d-inline-block text-light mb-0 float-right">
                    <strong>{ title }</strong>
                </h4>
            </header>
            <div className="bs-canvas-content px-3 py-2">
                <div className='p-2'>
                    { children }
                </div>
            </div>
        </div>
    )
}

export default OffCanvasSidebar;