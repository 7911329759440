import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import Navigation from '../Navigation';

//import DashboardPage from '../Dashboard';
import SignInPage from '../SignIn';
import SignUpPage from '../SignUp';
import SSOSignIn from '../SignIn/SSO';
import PasswordForgotPage from '../PasswordForgot';

import ApplicationsPage from '../Applicants';
import QuestionnairesPage from '../Questionnaires';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import NotFound from '../NotFound';
import SitesPage from '../Sites';

import Container from 'react-bootstrap/Container';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import 'bootstrap/dist/css/bootstrap.min.css';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
  
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.5,
  });

const ENVID = process.env.REACT_APP_ENV_ID;

console.log(ENVID + " >                     _                 _      _     _          ");
console.log(ENVID + " > _ __ ___  _   _ ___(_)_ __ ___  _ __ | | ___| |__ (_)_ __ ___ ");
console.log(ENVID + " >| '_ ` _ \\| | | / __| | '_ ` _ \\| '_ \\| |/ _ \\ '_ \\| | '__/ _ \\");
console.log(ENVID + " >| | | | | | |_| \\__ \\ | | | | | | |_) | |  __/ | | | | | |  __/");
console.log(ENVID + " >|_| |_| |_|\\__, |___/_|_| |_| |_| .__/|_|\\___|_| |_|_|_|  \\___|");
console.log(ENVID + " >           |___/                |_|                            ");

const App = () => (
    <Router>

     <Navigation />
        <main>
            <Container fluid>
                <div className="pt-lg-5 pt-md-4 pt-2">
                    <Switch>
                        <Route exact path="/" component={SignInPage} />
                        <Route path={ROUTES.SSO_SIGN_IN} component={SSOSignIn} />
                        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
                        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                        <Route path={ROUTES.PASSWORD_FORGOT} component={PasswordForgotPage} />
                        {/*  <Route path={ROUTES.DASHBOARD} component={DashboardPage} /> */}
                        <Route path={ROUTES.APPLICANTS} component={ApplicationsPage} />

                        <Redirect from="/candidates/:id" to={`${ROUTES.APPLICANTS}/:id`} />
                        <Redirect from="/candidates" to={ROUTES.APPLICANTS} />
                        <Redirect from="/applications/:id" to={`${ROUTES.APPLICANTS}/:id`} />
                        <Redirect from="/applications" to={ROUTES.APPLICANTS} />

                        <Route path={ROUTES.QUESTIONNAIRES} component={QuestionnairesPage}/>
                        <Route path={ROUTES.APPLICATIONFORMS} component={QuestionnairesPage}/>
                        <Route path={ROUTES.SITES} component={SitesPage}/>
                        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
                        <Route path={ROUTES.ADMIN} component={AdminPage} />
                        <Route component={NotFound}/>
                    </Switch>
                </div>
            </Container>
        </main>
    </Router>
  );
   
  export default withAuthentication(App);