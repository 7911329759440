import React from 'react';
import { Link, NavLink } from 'react-router-dom';
 
import { SignOutButton } from '../SignOut';
import { AuthUserContext } from '../Session';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import styles from './Navigation.module.scss';

const Navigation = ({ authUser }) => (
    <Navbar bg="brand-dark" expand="md" navbar="dark" variant="dark" id="main-navigation">
        <Navbar.Brand href={ROUTES.APPLICANTS}>
            mysimple<span className={styles.text_brand_primary}>hire</span>
            { process.env.REACT_APP_ENV_ID === 'PRD' ? "" : process.env.REACT_APP_ENV_ID }
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="top-nav"/>
            <AuthUserContext.Consumer>
                { authUser => authUser && (authUser.tenantId || authUser.global_admin)
                    ? <NavigationAuth authUser={authUser}/>
                    : <NavigationNonAuth showSignOut={authUser != null}/>
                }
            </AuthUserContext.Consumer>
    </Navbar>
);
 
const NavigationAuth = ({authUser}) => {
    const isAdmin = authUser.roles && !!authUser.roles.includes(ROLES.ADMIN);
    const hasSites = authUser.sites && authUser.sites.length > 0;

    return (    
        <Navbar.Collapse id="top-nav">
            <Nav className="mr-auto">
                { hasSites && 
                    <li className="nav-item">
                        <NavLink className="nav-link" to={ROUTES.APPLICANTS}>Applicants</NavLink>
                    </li>
                }
                { isAdmin && (
                <li className="nav-item">
                    <NavLink className="nav-link" to={ROUTES.QUESTIONNAIRES}>Questionnaires</NavLink>
                </li>
                )}
            </Nav>
            <Nav className="ml-auto" id='user-dropdown'>
                {/*
                <li className="nav-item">
                    <NavLink className="nav-link" to={ROUTES.APPLICATIONFORMS}>Application Forms</NavLink>
                </li>
                */}
                <li className="nav-item">
                    <NavLink className="nav-link" to={ROUTES.SITES}>Sites</NavLink>
                </li>
                <NavDropdown title={authUser.username}>
                    <div className='bg-dark'>
                        <div><NavLink className='btn btn-dark' to={ROUTES.ACCOUNT}>Settings</NavLink></div>
                        <div><SignOutButton/></div>
                    </div>
                </NavDropdown>
            </Nav>
        </Navbar.Collapse>
    )
};
 
const NavigationNonAuth = ({showSignOut}) => (
    <ul className="navbar-nav">
        <li className="nav-item">
            { showSignOut
                ? <SignOutButton />
                : <Link className="nav-link" to={ROUTES.SIGN_IN}>Sign In</Link>
            }
        </li>
    </ul>
);
 
export default Navigation;