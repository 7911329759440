import React from 'react';
import RenderAvailability from './Renderers/RenderAvailability';
import RenderPreviousEmployment from './Renderers/RenderPreviousEmployment';

const tryGetTitle = (question, propertyName) => {
    switch (question.type) {
        case "matrixdynamic":
            return question.columns.filter(x => x.name == propertyName)[0]?.title ?? propertyName;
        case "paneldynamic":
            return question.templateElements.filter(x => x.name == propertyName)[0]?.title ?? propertyName;
        default:
            return propertyName;
    }
}

const tryGetAnswer = (question, currentApplicationAnswers) => {
    let answer = currentApplicationAnswers[question.name];
    if (question.name === "agreement" || question.name ==="eoe" )
    {
        return (<div dangerouslySetInnerHTML={{ __html: question.html }}/>)
    }

    switch (question.type)
    {
        case "matrixdynamic":
            try
            {
                return (
                    <div className="d-block container mt-2">
                    { answer && answer.map((element, index) => {
                        let percent = Math.floor((100/ Object.entries(element).length)) + "%";
                        return (
                        <div className="row" key={index}>
                            {
                                Object.entries(element).map(([k, v], idx) => (
                                    <div style={{width:percent}} key={idx}>
                                        <label className="aaLabel">{tryGetTitle(question,k)}</label>
                                        <div className={aClassName(question)}>{v}</div>
                                    </div>
                            ))
                            }
                        </div>
                    )})
                    }
                    { !answer && 
                        <span>—</span>
                    }
                    </div>
                )
            } catch(err) {
                return ( <div>
                    <span className="fa fa-warning text-danger"> Oops! There was a problem displaying this answer. Please contact support. Raw data is displayed below.</span>
                    { answer &&
                    <div className="border m-2 p-1">{JSON.stringify(answer)}</div>
                    }
                </div>);
            }
        case "paneldynamic":
            try
            {
                if (question.name === "previousEmployment") {
                    return RenderPreviousEmployment(answer);
                }

                return (
                    <div>
                    { answer && answer.map((element, index) => (
                        <div className="card m-2 p-1" key={index}>
                            {
                                Object.entries(element).map(([k, v], idx) => (
                                    <div className="m-1 p-1" key={idx}>
                                        <label className="aaLabel">{tryGetTitle(question,k)}</label>
                                        <div className={aClassName(question)}>{v}</div>
                                    </div>
                            ))
                            }
                        </div>
                    ))
                    }
                    { !answer && 
                        <span>—</span>
                    }
                    </div>
                )
            } catch(err) {
                return ( <div>
                    <span className="fa fa-warning text-danger"> Oops! There was a problem displaying this answer. Please contact support. Raw data is displayed below.</span>
                    { answer &&
                    <div className="border m-2 p-1">{JSON.stringify(answer)}</div>
                    }
                </div>);
            }
        case "matrixdropdown":
            try
            {
                if (answer === undefined) {
                    return (<span>—</span>);
                }

                if (answer.Sunday && answer.Monday) {
                    return RenderAvailability(answer);
                }

                return (
                    <div><span className="fa fa-warning text-danger"> Oops! There is no rendering definition for this question. Please contact support. Raw data is displayed below.</span>
                    { answer &&
                        <div className="border m-2 p-1">{JSON.stringify(answer)}</div>
                    }
                    </div>
                )
            } catch(err) {
                <div><span className="fa fa-warning text-danger"> Oops! There is no rendering definition for this question. Please contact support. Raw data is displayed below.</span>
                { answer &&
                    <div className="border m-2 p-1">{JSON.stringify(answer)}</div>
                }
                </div>
            }
            break;
        case "checkbox":
            return (answer && answer !== "") ? answer.join("; ") : "—";
            break;
        default:
            return (answer && answer !== "") ? answer : "—";
    }
}

const renderEmployment = (answser) => {
    return "";
}



const renderedId = (props) => {
    return (props["type"] ?? "") + (props["name"] ?? (props["title"].replace(/\s/,"")));
}

const qClassName = (props) => {
    let baseClass = props["type"] + "Question";
    switch(props["type"]) {
        case "panel":
            let customCSSPanels = ["aboutSelf", "agreementAndEOE"];
            if (customCSSPanels.includes(props["name"])) {
                return props["name"] + "Panel";
            }

            return `aaPanel ${ props["addDisplayCSS"] ?? ''}`;
        default:
            return `${baseClass} ${props["addDisplayCSS"] ?? ''}`;
    }
}

const aClassName = (props) => {
    switch(props["type"]) {
        default:
            return props["type"] + "Answer answer";
    }
}

const AnsweredApplication = (props) => {

    return (
        <div className="answeredApplication">
        {
            props.questionnaire
            ?
            Object.entries(props.questionnaire.pages).map(([key, value]) => (
                <div key={key} className="pageElement" id={"page" + renderedId(value)}>
                    <div><h4>{value["title"]}</h4></div>
                    <div>
                        <ApplicationDisplay questions={value.elements} answers={props.answers}/>
                    </div>
                </div>
            ))
            : "Loading..."
        } 
        </div>          
    )
}

const ApplicationDisplay = (props) => {
    let pageDisplayQuestions = [];
    // our base case, if we have no items, render nothing.
    if (!props.questions)
    {
        return null;
    }

    return props.questions.map(question => {
        if (question.name.startsWith("msh_hpot_")) {
            return "";
        }

        if (question.visibleIf && question.visibleIf !== '' && tryGetAnswer(question, props.answers) === '—') {
            return "";
        }

        if (pageDisplayQuestions.includes(renderedId(question)))
        {
            return "";
        }

        pageDisplayQuestions.push(renderedId(question));

        return (
            <section key={question.name} id={question.name}>
                    <div className={`h-100 ${ qClassName(question)}` } id={renderedId(question)}>
                        <label className="aaLabel">{question.title ?? question.name}
                            {question.description ? <div className='questionDescription'>{question.description}</div> : ""}
                        </label>
                        {(question.elements 
                            ? <div className="containerElements">
                                <ApplicationDisplay questions={question.elements} answers={props.answers}/>
                                </div>
                            : <div className={ aClassName(question) }>
                                { tryGetAnswer(question, props.answers) }
                                </div>
                            )}
                    </div>
            </section>
        )
    })
}

export default AnsweredApplication;