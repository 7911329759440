import React, { useState } from 'react';
import { Link  } from 'react-router-dom';
import SignUpWizard from './SignUpWizard';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import AboutYou from './AboutYou';
import YourLocation from './YourLocation';
import { ToastContainer, toast } from 'react-toastify';

const INITIAL_FIELDS = {
    name: '',
    email: '',
    website: '',
    passwordOne: '',
    passwordTwo: '',
    address: {
        companyName: '',
        country: 'USA',
        address1: '',
        address2: '',
        city: '',
        state: '',
        province: '',
        ZIP: ''
    }
};

const SignUpPageBase = (props) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [fields, setFields] = useState(INITIAL_FIELDS);
    const [isCreateButton, setIsCreateButton] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isInvalid, setIsInvalid] = useState(true);

    let page1Text = "Let's get started! Tell us about your business:";
    let page1JSX = <p>{page1Text}</p>;

    const [currentStepText, setCurrentStepText] = useState(page1Text);
    const [currentStepJSX, setCurrentStepJSX] = useState(page1JSX);
    const [icon, setIcon] = useState("img/store.svg")

    const isAddressValid = (values) =>
        values.companyName !== "" &&
        values.country !== "" &&
        values.address1.length > 3 &&
        values.city.length >= 1 &&
            ((values.country === "USA" && values.state !== "") || values.country !== "USA") &&
            values.ZIP.length > 3;
    

    const handleAddressChange = (event) => {
        const newValues = {
            ...fields.address,
            [event.target.name]: event.target.value.replace(/[<>]+/g,"")
         };

         let update ={
            ...fields,
            "address": newValues
        }

        setFields(update);
        setIsInvalid(!isAddressValid(newValues));
    }

    const handleFieldChange = (event) => {
        const newValues = {
            ...fields,
            [event.target.name]: event.target.value.replace(/[<>]+/g,"")};

        let emailRegEx = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        let isInvalidEmail = !emailRegEx.test(newValues.email);

        let websiteRegex = new RegExp(/^([(\w)-]+\.)+\w{2,}$/);
        let isInvalidWebsite = (newValues.website.length > 1 && !websiteRegex.test(newValues.website));

        let invalid = isInvalidEmail ||
                        isInvalidWebsite ||
                        newValues.email === '' ||
                        newValues.name ==='' ||
                        (currentStep == 2 && (
                            newValues.passwordOne === '' ||
                            newValues.passwordTwo === '' ||
                            newValues.passwordOne != newValues.passwordTwo
                        ));

        if ((event.target.name === "email" && isInvalidEmail) ||
            (event.target.name === "website" && isInvalidWebsite) ||
            event.target.value.length < 2) {
            event.target.classList.add("border-danger");
        } else {
            event.target.classList.remove("border-danger");
        }

        setIsInvalid(invalid);

        setFields(newValues);
    }

    const handlBackButton = () => {
        let newStep = currentStep - 1;
        setCurrentStep(newStep);
        if (newStep === 1) {
            setIsCreateButton(false);
            setCurrentStepText("Let's get started! Tell us about your business:");
            setCurrentStepJSX(<p>Let's get started! Tell us about your business:</p>);
            setIcon("img/store.svg")
            setIsInvalid(false);
        }
    }

    const handleNextClick = () => {
        let newStep = currentStep + 1;
        setCurrentStep(newStep);
        if (newStep === 2) {
            setIsCreateButton(true);
            setCurrentStepText("Just a bit more information!");
            setCurrentStepJSX(<p>Just a bit more information!</p>);
            setIcon("img/management.svg")
        }
        setIsInvalid(true);
    }

    const handleCreateClick = () => {
        setIsSubmitting(true);

        props.firebase.doCustomerOnboard(fields)
            .then(() => {
                localStorage.setItem("evLast", "0");
                return props.firebase.doSendEmailVerification();
            })
            .then(_ => {
                setFields( { ...INITIAL_FIELDS });
                props.history.push(ROUTES.APPLICANTS);
            })
            .catch(error => {
                if (error.code === "already-exists") {
                    toast.error(<p>
                        An account for this email address already exists.
                        {' '}<a href='/signin' className='text-light font-weight-bold'><u>Sign In</u></a> or
                        {' '}<a href='/pw-forgot' className='text-light font-weight-bold'><u>Reset your Password</u></a>.</p>)
                } else {
                    toast.error(error.message);
                }
                setIsSubmitting(false);
            })
    }

    if (process.env.REACT_APP_ENABLE_SIGNUP === "false")
    {
        return (
            <div>Please <a href='mailto:info@mysimplehire.com'>email us</a> for an invite.</div>
        )
    }

    return (
        <div>
            <SignUpWizard
                title="Sign Up"
                description={currentStepJSX}
                image={icon}
                alt={`mysimplehire - ${currentStepText}`}>
                    <YourLocation
                        stepNum={1}
                        currentStep={currentStep}
                        fields={fields}
                        onFieldChange={handleAddressChange} />
                    <AboutYou
                        stepNum={2}
                        currentStep={currentStep}
                        fields={fields}
                        onFieldChange={handleFieldChange}
                        isSubmitting={isSubmitting}/>
                    <div className="text-right">
                        <a
                            href='/' 
                            className='btn btn-link pull-left'><span className='fa fa-arrow-left'></span> Return to Login</a>
                        {!isCreateButton && 
                            <button
                                type="button"
                                disabled={isInvalid}
                                onClick={handleNextClick}
                                className="btn btn-info">Next</button>
                        }
                        {isCreateButton && 
                            <>
                            { !isSubmitting &&
                                <>
                                <button
                                    type="button"
                                    onClick={handlBackButton}
                                    className="btn btn-secondary mr-2">Back</button>
                                <button
                                    type="button"
                                    disabled={isInvalid || isSubmitting}
                                    onClick={handleCreateClick}
                                    className="btn btn-success">Create Account</button>
                                </>
                            }
                            { isSubmitting &&
                                <div className="text-center">
                                    <span className="fa fa-cog fa-spin"></span>&nbsp;Creating Your Account!
                                </div>
                            }
                            </>  
                        }
                    </div>
            </SignUpWizard>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                />
        </div>
    )
}

const SignUpLink = () => (
    <p className='text-center'>
        {process.env.REACT_APP_ENABLE_SIGNUP === "true" && 
            <small>Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link></small>
        }
    </p>
)

const SignInLink = () => (
    <p className='text-center'><small>
        Already have an account? <Link to={ROUTES.SIGN_IN}>Sign In</Link>
    </small></p>
)

const SignUpPage = withFirebase(SignUpPageBase);

export default SignUpPage;
export { SignUpLink };