
const SITE_MODAL_MODE = {
    ADD: "ADD",
    EDIT: "EDIT"
};

const SITE_CHANGE_TYPE = {
    TOGGLE: "TOGGLE",
    CHANGE: "CHANGE"
}

const BLANK_SITE = {
    id: 0,
    site: {
        status: 1,
        companyName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        ZIP: '',
        notificationEmailAddress: '',
        acceptingApplications: true,
        primaryContact: {
            displayName: '',
            emailAddress: '',
            title: ''
        }
    }
};

export { SITE_MODAL_MODE, BLANK_SITE, SITE_CHANGE_TYPE };