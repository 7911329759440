import React from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import SiteItemForm, { isValidSite } from './SiteItemForm';
import { SITE_CHANGE_TYPE, SITE_MODAL_MODE } from './enums';


const SiteEditModal = (props) => {
    let site = props.siteDoc.site;
    return (
      <>
  
        <Modal
            size="xl"
            show={props.show}
            onHide={() => props.onClose()}
            backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title><i className="fa fa-building-o mr-2"></i>
                    { props.mode === SITE_MODAL_MODE.EDIT
                        ? <span>Edit Site</span>
                        : <span>Add New Site</span>
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <SiteItemForm
                    site={site}
                    onUpdateMap={(e) => props.onChangePrimaryContactMap(e)}
                    onToggle={(e) => props.onSiteChange(SITE_CHANGE_TYPE.TOGGLE, e)}
                    onChange={(e) => props.onSiteChange(SITE_CHANGE_TYPE.CHANGE, e)}/>
                </Form>
                <small><em>ID: {props.siteDoc.id}</em></small>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.onClose()}>
                    Cancel
                </Button>
                <button typs="submit" className="btn btn-success" disabled={!isValidSite(props.siteDoc.site)} onClick={(e) => props.onSubmit(e)}>
                    { props.mode === SITE_MODAL_MODE.ADD 
                        ? <span>Add New Site</span>
                        : <span>Update Site</span>
                    }
                </button>
            </Modal.Footer>
        </Modal>
      </>
    );
  }

  export default SiteEditModal;