import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import AddressFormFields from '../Common/AddressFields'

const CompanyFields = props => {
    let company = props.company;
    return (
        <section>
            <Form.Group controlId="fgCompanyName">
                <Form.Label>Business Name <sup>*</sup></Form.Label>
                <InputGroup>
                    <Form.Control
                        type="text"
                        name="companyName"
                        value={company.companyName}
                        onChange={props.onChange}
                        placeholder="Company Name"
                        required/>
                        <InputGroup.Append>
                            <InputGroup.Text><i className="fa fa-building-o"></i></InputGroup.Text>
                        </InputGroup.Append>

                </InputGroup>

            </Form.Group>
            <AddressFormFields onChange={props.onChange} address={ company }/>
        </section>
    );
}

export default CompanyFields;