import React, { useEffect, useState } from 'react';
import { currentAuth } from '../Session';
import { withFirebase } from '../Firebase';
import ApplicantStatusSelector, { StatusPopOver } from './ApplicantStatusSelector';
import AnsweredApplication from './CompletedApplication';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import styles from './Applications.module.scss';
import ApplicantNotesSidebar from './ApplicantNotesSidebar';
import useApplicantItemData from './useApplicantItemData';
import { copyTextToClipboard } from '../../services/copyText';

const ApplicantItem = (props) => {
//    const [applicationId, setApplicationId] = useState('');
    const [appValues, setAppValues] = useState({});
    const [questionnaire, setQuestionnaire] = useState(null);
    const [customCSS, setCustomCSS] = useState(null);
    const [currentApplicationAnswers, setCurrentApplicationAnswers] = useState([]);
    const [appDateObj, setAppDateString] = useState({appDateString: ""});
    const [loading, setLoading] = useState(true);
    const [appStatus, setAppStatus] = useState('Unreviewed');
    const [title, setTitle] = useState('');
    const [showNotes, setShowNotes] = useState(false);
    const [appNotes, setAppNotes] = useState([{ timestamp: new Date(), note: "This is a note"}]);
    
    const { tenantId } = currentAuth();
    const { getApplicationData, getApplicationQuestionnaire } = useApplicantItemData(props.firebase, tenantId);

    const appId = props.state ? props.state.applicationId : props.match.params.id;

    const setupApplicationViewData = (application, applicationJSON) => {
        updateAppValues(applicationJSON);
        setCurrentApplicationAnswers(applicationJSON);
        setAppStatus(application.status ?? "Unreviewed");
        let appDate = new Date((application.applicationDate ?? application.received).seconds * 1000);
        setAppDateString({appDateString: moment(appDate).format('lll')});
    }

    const setupApplicationQuestionnaire = (questionnaireId, targetLocation) => {
        getApplicationQuestionnaire(questionnaireId)
            .then(({ questionnaire, questionnaireJSON }) => {
                if (!questionnaire || !questionnaireJSON) {
                    return;
                }
        
                setQuestionnaire(questionnaireJSON);
                setCustomCSS(questionnaire.customCSS ? questionnaire.customCSS : "");
        
                setTitle(questionnaire.title ?? `${targetLocation} Employment Application`);        
            });
    }

    const copyText = async(address) => {
        if (await copyTextToClipboard(address)) {
            toast.success(<div className='text-center'>
                Email address copied to clipboard!<br/>
                <a class='text-light btn btn-link' href={`mailto:${address}`}><strong>Open in your Email App</strong>
                <span className="fa fa-envelope ml-1"></span></a></div>)
        } else {
            toast.error("Unable to copy email address to clipboard.");
        }
    }

    useEffect(() => {     
        getApplicationData(appId)
            .then(({application, applicationJSON}) => {

                if (!application || !applicationJSON) {
                    setTitle('Unable to location selected application');
                    return;
                }

                setupApplicationViewData(application, applicationJSON);
        
                setupApplicationQuestionnaire(application.questionnaireId, application.targetLocation);
         })
         .then(() => setLoading(false));
    }, [appId, props.firebase])

    const updateAppValues = (applicationJSON) => {
        let appValues = {
            mailtoSubject: `RE%3A%20Employment%20Application`,
            mailBody: "",
            phoneNumber: applicationJSON["phone"]
                            ?? applicationJSON["ph1"] + applicationJSON["ph2"] + applicationJSON["ph3"],
            applicantFullName: applicationJSON["fullName"]
                                ?? `${applicationJSON["fname"]} ${applicationJSON["lname"]}`,
            applicantEmail: applicationJSON["email"]
        };

        appValues.mailBody = `Hi%20${appValues.applicantFullName},%0D%0A%0D%0AThanks%20for%20your%20application.`;
        setAppValues(appValues);
    }

    const handleStatusChanged = async(name, applicationId, status) => 
    {
        let { tenantId, uid, username } = currentAuth();
        let results = await props.firebase.updateApplicationStatus(tenantId, applicationId, status, uid, username);
        if (!results.success) {
             toast.error(`Unable to update ${name} from status to ${status}.`);
        } else {
             setAppStatus(results.status);
             toast.success(`Updated ${name} to ${status}`);
        }
    };

    return (
        <>
        { loading &&
            <div>
                <span className="fa fa-spinner fa-spin mr-2"></span>Loading...
            </div>
        }
        { !loading &&
            <div>
                    <style id='customCSSHere'>
                        { customCSS }
                    </style>
                    <div className={`${styles.sticky} d-print-none`}>
                            <div className="row">
                                <div className='col-xs-12 col-sm-6'>
                                    <h4 className="applicantNameTop" title={appValues.applicantFullName}>
                                    <span
                                        className={`mr-2 ${styles.text_brand_primary} d-none d-md-inline-block`}>
                                            Applicant:</span>
                                            {appValues.applicantFullName}
                                    </h4>
                                </div>
                                <div className='d-none d-sm-block col-sm-6 text-right'>
                                <span className='text-muted mr-2'>Status:</span>
                                    <ApplicantStatusSelector
                                            status={appStatus}
                                            placement="bottom"
                                            overlay={StatusPopOver(appValues.applicantFullName, appId, handleStatusChanged)}/>
                                </div>
                            </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <h6 className="text-muted">
                                    Applied:&nbsp;
                                        <span id="appliedDisplay">
                                            { appDateObj.appDateString } 
                                        </span>
                                </h6>
                                <div className='d-sm-none d-block'>
                                    <h6><span className='text-muted mr-2'>Status:</span>
                                    <ApplicantStatusSelector
                                            status={appStatus}
                                            placement="bottom"
                                            overlay={StatusPopOver(appValues.applicantFullName, appId, handleStatusChanged)}/>
                                    </h6>
                                </div>
                            </div>
                            <div className="col-md-6 d-none d-md-block">
                                <div className="d-flex justify-content-end">
                                    { appValues && appValues.applicantEmail &&
                                        <div>
                                            <label className="sr-only">Copy Email Address to clipboard</label>
                                            <div
                                                className="btn btn-sm btn-outline-secondary mr-2"
                                                onClick={() =>{copyText(appValues.applicantEmail)}}>
                                                <span className="fa fa-copy mr-1"></span> Copy Email Address
                                            </div>
                                        </div>
                                    }
                                    { appValues && appValues.phoneNumber && 
                                        <div>
                                            <label className="sr-only">Call applicant</label>
                                            <a  className="btn btn-sm btn-outline-secondary mr-2" href={`tel:${appValues.phoneNumber}`}>
                                                <span className="fa fa-phone d-print-none"></span> Call {appValues.phoneNumber}
                                            </a>
                                        </div>
                                    }
                                    <div>
                                        <label className="sr-only">Notes</label>
                                        <button className="btn btn-sm btn-outline-secondary mr-2" onClick={() => setShowNotes(true)} >
                                            <span className="fa fa-sticky-note-o d-print-none"></span> Notes
                                        </button>
                                    </div>
                                    <div>
                                        <label className="sr-only">Print applicant application</label>
                                        <button className="btn btn-sm btn-outline-secondary" onClick={() => window.print()} >
                                            <span className="fa fa-print d-print-none"></span> Print
                                        </button>
                                    </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="row applicationBody mb-5">
                        <div className="col-lg-8 mx-auto col-md-10 col-sm-12">
                            {
                                <>
                                <h4>{title}</h4>
                                </>
                            }
                        <AnsweredApplication questionnaire={questionnaire} answers={currentApplicationAnswers}/>
                        </div>
                    </div>
                    <div className="thumbNav p-3 d-md-none fixed-bottom d-print-none">
                        <div className="d-flex justify-content-around text-light">
                            { currentApplicationAnswers && appValues.applicantEmail &&
                                <div className="text-center">
                                        <a href={`mailto:${appValues.applicantEmail}?Subject=${appValues.mailtoSubject}&Body=${appValues.mailBody}`} className="text-light">
                                            <small><span className="fa fa-envelope"></span> Email</small>
                                        </a>
                                </div>
                            }
                            { appValues && appValues.phoneNumber && 
                                <div className="text-center">
                                    <a href={`sms:${appValues.phoneNumber}`} className="text-light">
                                    <small><span className="fa fa-comment text-light"></span> Text</small>
                                    </a>
                                </div>
                            }
                            { appValues && appValues.phoneNumber && 
                                <div className="text-center">
                                    <a href={`tel:${appValues.phoneNumber}`} className="text-light">
                                        <small><span className="fa fa-phone text-light"></span> Call</small>
                                    </a>
                                </div>
                            }
                        </div>
                    </div>
            </div>
        }
        <ApplicantNotesSidebar
            show={showNotes}
            applicantName={appValues.applicantFullName}
            applicationId={appId}
            onClose={() => setShowNotes(false)}
        />
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
        </>
    );
}

export default withFirebase(ApplicantItem);
