import React, { useEffect, useState } from 'react';
import { currentAuth } from '../Session';
import { Navbar } from 'react-bootstrap';
import { AppStatus, AppStatusOptions } from '../../constants/statuses';
import Select from 'react-select';
import { withFirebase } from '../Firebase';
import ApplicantTable from './ApplicantTable';
import { ToastContainer, toast } from 'react-toastify';
import styles from './Applications.module.scss';
import Cookies from 'js-cookie';

import 'react-toastify/dist/ReactToastify.css';

const ApplicationsBase = (props) => {
    const defaultStatusFilter = [
        AppStatus.UNREVIEWED,
        AppStatus.REVIEWED,
        AppStatus.INTERVIEW_SCHEDULED,
        AppStatus.INTERVIEW_COMPLETE,
        AppStatus.ACCEPTED_OFFER
    ];

    const getAppDateOffsetCookieOrDefault = () => {
        let offsetCookie = Cookies.get('appDateOffsetFilter');
        return (offsetCookie && offsetCookie !== "") ? parseInt(offsetCookie) : -60;
    }

    const getStatusFilterCookiSelection = () => {
        let cookieStatusFilter = Cookies.get('appStatusFilter');
        return (cookieStatusFilter === undefined || cookieStatusFilter === "")
            ? defaultStatusFilter
            : cookieStatusFilter.split(',');
    }

    const [isLoading, setLoading] = useState(false);
    const [fullApplicationList, setFullApplicationList] = useState([]);
    const [applications, setApplications] = useState([]);
    const [appDateOffset, setAppDateOffset] = useState(getAppDateOffsetCookieOrDefault());
    const [currentStatusFilter, setCurrentStatusFilter] = useState(getStatusFilterCookiSelection());
    const [matchingFilterCount, setMatchingFilterCount] = useState(0);
    const [nameSearch, setNameSearch] = useState(Cookies.get('appNameSearch') ?? '');

    const getOffsetDate = (offset) => new Date(new Date(new Date().setDate(new Date().getDate() + (offset))).setHours(0,0,0,0));

    const defaultTimespanFilter = [
        { value: -30, label: `Since ${getOffsetDate(-30).toLocaleDateString()} (30 days)` },
        { value: -60, label: `Since ${getOffsetDate(-60).toLocaleDateString()} (60 days)` },
        { value: -90, label: `Since ${getOffsetDate(-90).toLocaleDateString()} (90 days)` },
        { value: -180, label: `Since ${getOffsetDate(-180).toLocaleDateString()} (6 mos)` },
        { value: -270, label: `Since ${getOffsetDate(-270).toLocaleDateString()} (9 mos)` },
        { value: -365, label: `Since ${getOffsetDate(-365).toLocaleDateString()} (1 Year)` },
    ]

    useEffect(() => {

        const fb = props.firebase;

        setLoading(true);

        let offsetDate = getOffsetDate(appDateOffset ?? -60);

        let query = fb
                        .tenantLevelApplicationsInfo(props.authUser.tenantId)
                        .where("applicationDate", ">=", offsetDate)
                        .orderBy("applicationDate", "desc");


        if (!props.authUser.tenant_admin) {
            query = query.where("siteId", "in", props.authUser.sites);
        }

        const unsubscribe =
            query
            .onSnapshot(snapshot => {
                let fullAppList = [];

                if (!snapshot.empty) {
                    snapshot.forEach(doc => {
                        let data = doc.data()
                        fullAppList.push( {
                            ...data,
                            id: doc.id,
                            applicationDate: data.applicationDate ?? data.received,
                            status: data.status ?? "Unreviewed",
                        });
                    })
                }

                setFullApplicationList(fullAppList);
                let filteredList = doFilterList(currentStatusFilter, fullAppList);
                if (nameSearch !== "") {
                     doNameSearch(nameSearch, filteredList);
                }
                setLoading(false);
            });

        return () => {
            unsubscribe();
        }
    }, [props, currentStatusFilter, appDateOffset]);

    const getFilterSelection = () => {
        return defaultTimespanFilter.filter(x => x.value === appDateOffset);
    }

    const getAppStatusOptions = () => {
        let results = [];
        currentStatusFilter.forEach((v) => {
            results = results.concat(AppStatusOptions.filter(x => x.value === v));
        })
        return results;
    }

    const updateApplicationStatus = async(name, applicationId, status) =>
    {
        let { tenantId, uid, username } = currentAuth();
        let results = await props.firebase.updateApplicationStatus(tenantId, applicationId, status, uid, username);
        if (!results.success) {
             toast.error(`Can't update ${name} to ${status}.`);
        } else {
             toast.success(`Updated ${name} to ${status}`);
        }
    };

    const onStatusFilter = (filters) => {
        let filter = [];
        filters.forEach(el => {
            filter.push(el.value);
        });
        doFilterList(filter, fullApplicationList);
        setCurrentStatusFilter(filter);
    }

    const onTimeWindowFilter = (filter) => {
        setAppDateOffset(filter.value);
        Cookies.set('appDateOffsetFilter', filter.value);
    }

    const doFilterList = (filter, appList) => {
        let filteredList = [...appList].filter((x) => {
            return filter.includes(x.status);
        });
        setApplications(filteredList);
        setMatchingFilterCount(filteredList.length);

        Cookies.set('appStatusFilter', filter.join(","));

        return filteredList;
    }

    const clearNameSearch = () => {
        onNameSearch({target: {value: ""}});
        Cookies.set('appNameSearch', '');
    }

    const onNameSearch = (e) => {

        setNameSearch(e.target.value);
        Cookies.set('appNameSearch', e.target.value);
        doNameSearch(e.target.value, fullApplicationList);
    }

    const doNameSearch = (val, appList) => {
        let typedValue = val.toLowerCase();

        let filteredList = doFilterList(currentStatusFilter, appList);

        var updatedFilter = [...filteredList].filter((x) => {
            return x.fullName.toLowerCase().indexOf(typedValue) !== -1;
        });

        setApplications(updatedFilter);
        setMatchingFilterCount(updatedFilter.length);

    }

    const selectStyles = {
        container: base => ({
          ...base,
          flex: 1
        })
      };
    return (
        <div id='applicationListContent'>

        <h2 className={styles.text_brand_primary}>Applicants</h2>
            <Navbar bg="brand-dark" expand="lg" navbar="dark" variant="dark">
            <Navbar.Brand>&nbsp;</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <div className="row w-100 mb-2">
                    <div className="col-xs-12 col-md-12 col-lg-5">
                    <label className="text-light">With Statuses</label>
                    <Select
                        defaultValue={ getAppStatusOptions() }
                        isMulti
                        closeMenuOnSelect={false}
                        onChange={onStatusFilter}
                        className="basic-multi-select"
                        options={AppStatusOptions}
                        style={{selectStyles}}
                        placeholder="Filter by Status..."
                        />

                    </div>
                    <div className="col-xs-12 col-md-12 col-lg-4">
                        <label className="text-light">Applied in the last</label>
                        <Select
                            defaultValue={  getFilterSelection() }
                            onChange={onTimeWindowFilter}
                            className="basic-multi-select"
                            style={{selectStyles}}
                            options={defaultTimespanFilter}
                            placeholder="within..."
                        />
                    </div>
                    <div className="col-xs-12 col-md-12 col-lg-3">
                        <label className="text-light">Name contains</label>
                        <div className="input-group">
                            <input
                                type="text"
                                value={nameSearch}
                                className="form-control"
                                placeholder="Name contains..."
                                onChange={onNameSearch}
                                />
                            <div className="input-group-append border-0" onClick={clearNameSearch}>
                                <div className="input-group-text bg-white">
                                    <span className="fa fa-small fa-times"></span>
                                </div>
                            </div>
                            <div className="input-group-append text-light">
                                <div className="input-group-text">
                                    <span className="fa fa-search"></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Navbar.Collapse>
            </Navbar>
            {applications
                ? <div>
                    { !isLoading
                        ?
                        <div>
                            <div><small><strong>{matchingFilterCount} {` matching ${(matchingFilterCount > 1) ? " applications" : " application"}`}</strong></small></div>
                            <ApplicantTable
                                data={applications}
                                authUser={props.authUser}
                                onApplicationStatusChange={updateApplicationStatus}/>
                        </div>
                        : <div>
                            <span className="fa fa-spinner fa-spin mr-2"></span>Loading...</div>
                    }
                </div>
                : <div>No applications have been received for your location.</div>
            }
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                />
        </div>
    );
}

const ApplicantsList = withFirebase(ApplicationsBase);

export default ApplicantsList;