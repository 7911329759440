const AppStatus = Object.freeze({
    UNREVIEWED: "Unreviewed",
    REVIEWED: "Reviewed",
    INTERVIEW_SCHEDULED: "Interview Scheduled",
    INTERVIEW_COMPLETE: "Interviewed",
    ACCEPTED_OFFER: "Offer Sent",
    ACCEPTED_HIRED: "Hired",
    DECLINED: "Declined Offer",
    REJECTED: "Rejected",
});

const AppStatusOptions = [
    { value: AppStatus.UNREVIEWED, label: AppStatus.UNREVIEWED },
    { value: AppStatus.REVIEWED, label: AppStatus.REVIEWED },
    { value: AppStatus.INTERVIEW_SCHEDULED, label: AppStatus.INTERVIEW_SCHEDULED },
    { value: AppStatus.INTERVIEW_COMPLETE, label: AppStatus.INTERVIEW_COMPLETE },
    { value: AppStatus.ACCEPTED_OFFER, label: AppStatus.ACCEPTED_OFFER },
    { value: AppStatus.ACCEPTED_HIRED, label: AppStatus.ACCEPTED_HIRED },
    { value: AppStatus.DECLINED, label: AppStatus.DECLINED },
    { value: AppStatus.REJECTED, label: AppStatus.REJECTED }
];

export { AppStatus, AppStatusOptions }