import React from 'react';

const AboutYou = (props) => {
    if (props.stepNum != props.currentStep) {
        return null;
    }

    const onChange = event => {
        if (props.onFieldChange) {
            props.onFieldChange(event);
        }
    }

    return (
        <>
            <div className="form-group">
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <span className="fa fa-fw fa-user"></span>
                    </span>                    
                </div>
                <label htmlFor="name" className="sr-only">Your Full Name</label>
                <input
                    name="name"
                    className="form-control"
                    value={props.fields.name}
                    disabled={props.isSubmitting}
                    onChange={onChange}
                    type="text"
                    placeholder="Your Full Name"
                    maxLength="80"
                    required="required"
                    autoFocus="autoFocus"
                />
            </div>
        </div>
        <div className="form-group">
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <span className="fa fa-fw fa-envelope"></span>
                    </span>                    
                </div>
                <label htmlFor="email" className="sr-only">Work Email Address</label>
                <input
                    name="email"
                    className="form-control"
                    value={props.fields.email}
                    disabled={props.isSubmitting}
                    maxLength="200"
                    onChange={onChange}
                    type="text"
                    placeholder="Work Email Address"
                    required="required"
                />
            </div>
        </div>
        <div className="form-group">
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <span className="fa fa-fw fa-globe"></span>
                    </span>                    
                </div>
                <label htmlFor="website" className="sr-only">Your Website Address (optional)</label>
                <input
                    name="website"
                    className="form-control"
                    value={props.fields.website}
                    onChange={onChange}
                    maxLength="50"
                    disabled={props.isSubmitting}
                    type="text"
                    placeholder="Company Website"
                />
            </div>
        </div>

        <div className="text-right">
            <small>Minimum 8 characters</small>
        </div>
        <div className="form-group">
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <span className='fa fa-fw fa-lock'></span>
                    </span>                    
                </div>
                <label htmlFor="passwordOne" className="sr-only">Enter your password</label>
                <input
                    name="passwordOne"
                    className="form-control"
                    value={props.fields.passwordOne}
                    onChange={onChange}
                    disabled={props.isSubmitting}
                    type="password"
                    placeholder="Password"
                    required="required"
                />
                <div className="input-group-append">
                    <span className="input-group-text">
                        { (!props.fields.passwordOne || props.fields.passwordOne === '' || props.fields.passwordOne.length < 8) &&
                            <span className="fa fa-fw">&nbsp;</span>
                        }

                        { props.fields.passwordOne && props.fields.passwordOne !== '' && props.fields.passwordOne.length > 7 &&
                            <span className="fa fa-fw fa-check text-success"></span>
                        }
                    </span>                    
                </div>
            </div>
        </div>
        <div className="form-group">
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <span className="fa fa-fw fa-lock"></span>
                    </span>                    
                </div>
                <label htmlFor="passwordTwo" className="sr-only">Confirm your password</label>
                <input
                    name="passwordTwo"
                    className="form-control"
                    value={props.fields.passwordTwo}
                    onChange={onChange}
                    disabled={props.isSubmitting}
                    type="password"
                    placeholder="Confirm Password"
                    required="required"
                />
                <div className="input-group-append">
                    <span className="input-group-text">
                        { (props.fields.passwordTwo === '') &&
                            <span className="fa fa-fw">&nbsp;</span>
                        }
                        { props.fields.passwordTwo !== '' && props.fields.passwordOne !== props.fields.passwordTwo &&
                            <span className="fa fa-fw fa-times text-danger"></span>
                        }
                        { props.fields.passwordTwo !== '' && props.fields.passwordOne === props.fields.passwordTwo &&
                            <span className="fa fa-fw fa-check text-success"></span>
                        }
                    </span>                    
                </div>
            </div>
        </div>

        </>
    )
}

export default AboutYou;