// public routes
export const HOME = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const SSO_SIGN_IN = '/signin/SSO';
export const SSO_SIGN_IN_BRANDED = `${SSO_SIGN_IN}/:id`;
export const PASSWORD_FORGOT = '/pw-forgot';

export const EMAIL_VERIFICATION_HOME = process.env.REACT_APP_ENV_ID === 'PRD' ? 'https://app.mysimplehire.com' : 'https://dev.mysimplehire.com';

// protected routes
export const DASHBOARD = '/home';
export const ACCOUNT = '/account';

export const APPLICANTS = "/applicants";
export const APPLICANTS_DETAIL = `${APPLICANTS}/:id`;

export const QUESTIONNAIRES = "/questionnaires";
export const QUESTIONNAIRES_DETAIL = `${QUESTIONNAIRES}/:id`;

export const APPLICATIONFORMS = "/applicationforms";
export const APPLICATIONFORMS_DETAIL = `${APPLICATIONFORMS}/:id`;

export const SITES = "/sites";

export const ADMIN = '/admin';
export const ADMIN_USER_DETAILS = '/admin/users/:id';

