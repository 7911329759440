export default(answer) => {
    if (!answer || answer.length == 0) {
        return (<span>None entered</span>);
    }

    const reNum = (d) => {
        let slash = d.indexOf("/");
        return d.substring(slash + 1, d.length) + d.substring(0, slash);
    }

    const psuedoDateSort = (emp1, emp2, useEnd) => {
        if (useEnd) {
            const end1 = reNum(emp1.endedHere);
            const end2 = reNum(emp2.endedHere);
            if (end1 > end2) return -1;
            if (end1 < end2) return 1
        }

        const s1 = reNum(emp1.startedHere);
        const s2 = reNum(emp2.startedHere);
        if (s1 > s2) return -1;
        if (s1 < s2) return 1;    }

    const currentJobs = answer
                        .filter(x => x["Still Employed Here"] === "Yes")
                        .sort((a, b) => psuedoDateSort(a, b, false));

    const leftJobs = answer
                        .filter(x => x["Still Employed Here"] === "No")
                        .sort((a, b) => psuedoDateSort(a, b, true));

    return (
        <div>
        {currentJobs.concat(leftJobs).map((x, idx) => (
                <div className="card m-1 p-1" key={idx}>
                    <div>
                        <label className="aaLabel">Employer</label>
                        <div className="answer">{x.employer}</div>
                    </div>
                    <div>
                        <label className="aaLabel">Position/Title</label>
                        <div className="answer">{x["Position/Title"]}</div>
                    </div>
                    { x.startedHere && 
                        <div>
                            <label className="aaLabel">From-To</label>
                            <div className="answer">{x.startedHere} - {(x["Still Employed Here"] === "Yes") ? "Still Employed" : x.endedHere}</div>
                        </div>
                    }
                    {!x.startedHere && 
                        <div>
                            <label className="aaLabel">Still Employed Here</label>
                            <div className="answer">{x["Still Employed Here"]}</div>
                        </div>
                    }
                    <div>
                        <label className="aaLabel">Supervisor</label>
                        <div className="answer">{x.Supervisor}</div>
                    </div>
                    <div>
                        <label className="aaLabel">May we contact?</label>
                        <div className="answer">{x.canContactEmployer}</div>
                    </div>
                    <div>
                        <label className="aaLabel">Address</label>
                        <div className="answer">{x.Address}</div>
                    </div>
                    <div>
                        <label className="aaLabel">Phone #</label>
                        <div className="answer">{x.Phone}</div>
                    </div>
                    <div>
                        <label className="aaLabel">Reason(s) for leaving</label>
                        <div className="answer">{x["Reasons for leaving"]}</div>
                    </div>
                </div>
        ))}
        </div>
    )
}