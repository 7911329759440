export const copyTextToClipboard = (textToCopy) => {
    // Asynchronously call copyTextToClipboard
    return copyTextToClipboardAsync(textToCopy)
    .then(() => {
        return true;
    })
    .catch((err) => {
        console.log(err);
        return false;
    });
}

const copyTextToClipboardAsync = async(text) => {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
    }
}